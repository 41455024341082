import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import googleLogo from './ic-google-logo.png';
import Logo from './Logo-chico.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import "./login.css";

const scope = 'email profile';
const responseType = 'code';
const CLIENT_ID = '595151583057-v1dk6bvkllasfqg121h76ucfnr37rlk5.apps.googleusercontent.com';
const REDIRECT_URI = 'https://dreamfly.es/api/login-google';

function LoginComponente(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [inputError, setInputError] = useState(false);
  const [credencialesError, setCredencialesError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [serverError, setServerError] = useState(false);

  // const [loginGoogle, setLoginGoogle] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [thinking, setThinking] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Función para validar el email
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleLoginGoogle = () => {
    // setLoginGoogle(true);
    // setTimeout(() => {
    //   setLoginGoogle(false);
    // }, 6000);

    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${responseType}&scope=${scope}`;
    window.location.href = googleAuthUrl;
  };

  const handleIniciarSesion = async (e) => {
    e.preventDefault();
    setThinking(true);
    if (email === '' || password === '') {
      setInputError(true);
      setThinking(false);
      setTimeout(() => {
        setInputError(false);
      }, 6000);
    } else if (!isValidEmail(email)) {
      setEmailError(true);
      setThinking(false);
      setPassword('');
      setTimeout(() => {
        setEmailError(false);
      }, 6000);
    } else {
      try {
        const response = await fetch('https://dreamfly.es/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email, password })
        });

        const data = await response.json();
        if (response.ok) {
          const token = data.token;
          if (token) {
            setThinking(false);
            props.setToken(token);
            window.location.href = props.redirect;
          }
        } else {
          if (data.message === 'Unauthorized') {
            setCredencialesError(true);
            setPassword('');
            setThinking(false);
            setTimeout(() => {
              setCredencialesError(false);
            }, 6000);
          } else if (data.message === 'Account not verified') {
            window.location.href = '/account-not-verified';
          } else if (data.message === 'Server error') {
            console.log("Error del servidor");
            setServerError(true);
            setThinking(false);
          } else {
            setServerError(true);
            setThinking(false);
            console.log("Sepa dios el error que es jajajaja");
          }
        }
      } catch (error) {
        setServerError(true);
        setThinking(false);
        console.error('There was a problem with the fetch operation:', error);
      }
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="logo">
            <img src={Logo} alt="Logo Dream Fly - Personaliza tus viajes gratis" />
          </div>
          <div className="container-backleft" aria-label='Pantalla de login'>
            <div className="container-overlay-left">
              <div className="container-img-left" />
              <div className="container-titulo-left" aria-label='Titulos login'>
                <label className="titulo">Bienvenido de nuevo</label>
                <label className="slogan">¿A dónde nos vamos hoy?</label>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="container-backright">
            <div className="container-overlay-right">
              <form onSubmit={handleIniciarSesion}>
                <div className="container-titulo-right" aria-label='Formulario inicio sesión'>
                  <h1 className="titulo-right">Iniciar sesión</h1>
                </div>
                <div className="form-container">
                  <div className="form-group" aria-label='Introduce su email'>
                    <input type="email" id="email" value={email} placeholder=" " autoComplete='username' onChange={(e) => setEmail(e.target.value.toLowerCase())} />
                    <label htmlFor="email" className="floating-label">Email</label>
                  </div>
                  <div className="form-group form-group-password" aria-label='Introduce su contraseña'>
                    <input
                      type={passwordVisible ? 'text' : 'password'}
                      id="password"
                      placeholder=" "
                      value={password}
                      autoComplete='current-password'
                      onChange={(e) => setPassword(e.target.value)}
                      aria-live={passwordVisible ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                    />
                    <label htmlFor="password" className="floating-label">Contraseña</label>
                    <span
                      className="toggle-password"
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                  <div className="forgot-password" aria-label='Enlace hacia página he olvidado contraseña'>
                    <a href="/forgotpassword">¿Olvidaste la contraseña?</a>
                  </div>
                </div>
                {inputError &&
                  <div className='alert alert-danger container-alert' aria-live='assertive'>
                    <span>Todos los campos son obligatorios.</span>
                  </div>}
                {emailError &&
                  <div className='alert alert-danger container-alert' aria-live='assertive'>
                    <span>El email introducido no es válido.</span>
                  </div>}
                {credencialesError &&
                  <div className='alert alert-danger container-alert' aria-live='assertive'>
                    <span >El usuario o la contraseña no son válidas.</span>
                  </div>}
                {serverError &&
                  <div className='alert alert-danger container-alert' aria-live='assertive'>
                    <span >Error del servidor. Por favor, inténtelo más tarde.</span>
                  </div>}
                {/* {loginGoogle &&
                  <div className='alert alert-danger container-alert' aria-live='assertive'>
                    <span >El login con Google está temporalmente deshabilitado, regístrese o inicie sesión con su email.</span>
                  </div>
                } */}
                <div className="signin-actions">
                  <button className="signin-btn" onClick={handleIniciarSesion} aria-label='Botón iniciar sesión'>
                    <div>
                      {thinking ?
                        (
                          <div className="puntos-animados-loading">
                            <div className="puntos"></div>
                            <div className="puntos" style={{ animationDelay: '0,2s' }}></div>
                            <div className="puntos" style={{ animationDelay: '0.4s' }}></div>
                          </div>
                        ) : (
                          <span>Iniciar sesión</span>
                        )
                      }
                    </div>
                  </button>
                  <div className="or-separator">
                    <span>o</span>
                  </div>
                  <div className="google-signin" aria-label='Otras opciones de inicio de sesión'>
                    <button className="signin-btn-google" onClick={handleLoginGoogle} aria-label='Iniciar sesión con Google'>
                      <img src={googleLogo} alt="Logo Google" className='logo-google-login' />
                      <span className='signin-span-google'>Continuar con Google</span>
                    </button>
                  </div>
                </div>
                <p className="signup-prompt" aria-label='Registrarse'>
                  ¿No tienes cuenta? <a href="/register" aria-label='Enlace a página de registro'>Regístrate ahora</a>
                </p>
              </form>
            </div>
          </div>
        </Col>
      </Row >
    </Container >
  );
}

export default LoginComponente;
