import React, { useEffect } from 'react';
import './overlay-hotel.css';

const OverlayHotel = (props) => {

    useEffect(() => {
        // Añadir clase para bloquear el scroll
        document.body.classList.add('no-scroll');

        return () => {
            // Remover clase al desmontar el componente
            document.body.classList.remove('no-scroll');
        };
    }, []);


    const handleClick = () => {
        props.handleBuyHotel();
    };

    return (
        <div className="overlay-hotel" aria-label='Reserva del alojamiento'>
            <div className="overlay-hotel-close" onClick={handleClick} aria-label='Cerrar reserva del alojamiento'>
                <i className="fas fa-times">   Cerrar reserva</i>
            </div>
            <div className="overlay-hotel-content" aria-label='Widget reserva del alojamiento'>
            <iframe border="0" src="https://es.trip.com/partners/ad/S517610?Allianceid=5116182&SID=101655884&trip_sub1=" id="S517610"></iframe>
            </div>
        </div>
    );
};

export default OverlayHotel;
