import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigation } from '../../components/Navbar/navigation';
import Header from '../../components/Header/header';
import Services from '../../components/Services/services';
import Features from '../../components/Features/features';
import TopLiked from '../top-liked-trips/top-liked';
import About from '../../components/About/about';
import Contact from '../../components/Contact/contact';

function Home({ landingPageData, token, logout }) {
  return (
    <>
      <Helmet>
        <title>Dream Fly - Crear viajes personalizados</title>
        <meta name="description" content="Dream Fly te crea viajes personalizados totalmente gratis con ayuda de una IA. Crear viajes con IA nunca ha sido tan fácil." />
        <meta name="keywords" content="Dream Fly, viajar, descubrir viajes, viajes personalizados, itinerarios gratis, planificación de viajes, destinos a medida, crear viajes con IA" />
      </Helmet>
      <div className='container-home'>
        <Navigation token={token} logout={logout}/>
        <Header data={landingPageData.Header} />
        <Services data={landingPageData.Services} />
        <TopLiked token={token}/>
        <About data={landingPageData.About} />
        <Contact data={landingPageData.Contact} />
      </div>
    </>
  )
}

export default Home;
