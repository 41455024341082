import React, { useEffect } from 'react';
import './overlay-error-creando.css';

const OverlayErrorCreando = (props) => {

    useEffect(() => {
        // Añadir clase para bloquear el scroll
        document.body.classList.add('no-scroll');
        
        return () => {
            // Remover clase al desmontar el componente
            document.body.classList.remove('no-scroll');
        };
    }, []);

    return (
        <div className="overlay-error-creando" aria-label='Alerta Pop Up'>
            <div className="overlay-error-creando-content">
                <h2>Error creando el itinerario</h2>
                <p>Ha ocurrido un error mientras se creaba el itinerario de su viaje. Por favor, inténtelo de nuevo.</p>
                <button onClick={props.handleClick}>Reintentar la creación</button>
            </div>
        </div>
    );
};

export default OverlayErrorCreando;
