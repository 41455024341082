import React from 'react'
import OverlayAlerta from '../OverlayAlerta/overlay-alerta'

function EmailSentFP() {
  return (
    <div aria-label='Correo enviado'>
        <OverlayAlerta
            titulo="¡Correo enviado!"
            subtitulo="Se ha enviado un correo para el cambio de contraseña a tu dirección de correo electrónico. Por favor, revisa tu bandeja de entrada y sigue las instrucciones para la contraseña de tu cuenta."
            btnMensaje="Iniciar sesión"
            redirect="/login" />
    </div>
  )
}

export default EmailSentFP