import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Logo from '../Login/Logo-chico.png';
import "./register.css";

//// ASYNCHRONOUS FUNCTION TO REGISTER AN USER ////
async function registerUser(credentials) {
    // TODO: Cambiar la dirección por la de producción
    return fetch('https://dreamfly.es/api/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

function RegisterComponent(props) {

    //// INPUTS ////
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    //// ERROR HANDLING ////
    //// LOCAL ONES ////
    const [inputError, setInputError] = useState(false);
    const [passwordDoesNotMatch, setPasswordDoesNotMatch] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [isNotChecked, setIsNotChecked] = useState(false);
    const [serverError, setServerError] = useState(false);
    //// SERVER ONES ////
    const [alreadyRegistered, setAlreadyRegistered] = useState(false);
    const [registrationError, setRegistrationError] = useState(false);

    //// PASSWORD VISIBILITY ////
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);

    const [thinking, setThinking] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(!passwordVisible2);
    };

    // Función para validar el email
    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleRegistrar = async (e) => {
        e.preventDefault();
        setThinking(true);
        //// INPUT VALIDATION (NO EMPTY STRINGS) ////
        if (username === '' || email === '' || password === '' || passwordConfirmation === '') {
            setInputError(true);
            setThinking(false);
            setTimeout(() => {
                setInputError(false);
            }, 6000);
            //// PASSWORDS MATCHING VALIDATION ////
        } else if (!isValidEmail(email)) {
            setEmailError(true);
            setThinking(false);
            setPassword('');
            setPasswordConfirmation('');
            setTimeout(() => {
                setEmailError(false);
            }, 6000);
        } else if (password !== passwordConfirmation) {
            setPasswordDoesNotMatch(true);
            setThinking(false);
            setTimeout(() => {
                setPasswordDoesNotMatch(false);
            }, 6000);
            //// REGISTER USER ////
        } else if (!isChecked) {
            setIsNotChecked(true);
            setThinking(false);
            setTimeout(() => {
                setIsNotChecked(false);
            }, 6000);
        } else {
            try {                
                const response = await registerUser({
                    username,
                    email,
                    password
                });
                if (response.message === 'Email sent') {
                    window.location.href = '/email-sent';
                } else {
                    if (response.message === 'Already registered') {
                        setAlreadyRegistered(true);
                        setPassword('');
                        setPasswordConfirmation('');
                        setThinking(false);
                        setTimeout(() => {
                            setAlreadyRegistered(false);
                        }, 6000);
                    } else if (response.message === 'Email not sent') {
                        window.location.href = '/email-not-sent';
                    } else {
                        setRegistrationError(true);
                        setThinking(false);
                        setTimeout(() => {
                            setRegistrationError(false);
                        }, 6000);
                    }
                }
            } catch (error) {
                setServerError(true);
                setThinking(false);
                console.error('There was a problem with the fetch operation:', error);
            }
        }
    };

    return (
        <Container>
            <Row>
                <Col>
                    <div className="logo">
                        <img src={Logo} alt="Logo Dream Fly" />
                    </div>
                    <div className="container-backright-register" aria-label='Página registro Dream Fly'>
                        <div className="container-overlay-right-register">
                            <div className="container-titulo-right-register" aria-label='Titulo página registro'>
                                <h1 className="titulo-right-register">Crear una cuenta</h1>
                            </div>
                            <form onSubmit={handleRegistrar}>
                                <div className="form-container-register" aria-label='Formulario registro Dream Fly'>
                                    <div className='alert alert-info' style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }} aria-label='Advertencia por campos obligatorios'>
                                        <span style={{ flexGrow: 1 }}>Los campos con asterisco (<span style={{ color: 'red' }}>*</span>) son obligatorios.</span>
                                    </div>
                                    <div className="form-group-register form-group-register-nombre" aria-label='Input nombre'>
                                        <input
                                            type="text"
                                            id="username"
                                            placeholder=" "
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                        <label htmlFor="username" className="floating-label">Nombre <span style={{ color: 'red' }}> *</span></label>
                                    </div>
                                    <div className="form-group-register form-group-register-email" aria-label='Input email'>
                                        <input
                                            type="email"
                                            id="email"
                                            placeholder=" "
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                        />
                                        <label htmlFor="email" className="floating-label">Email <span style={{ color: 'red' }}> *</span></label>
                                    </div>
                                    <div className="form-group-register form-group-register-password" aria-label='Input contraseña'>
                                        <input
                                            type={passwordVisible ? 'text' : 'password'}
                                            id="password"
                                            placeholder=" "
                                            value={password}
                                            autoComplete='new-password'
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <label htmlFor="password" className="floating-label">Contraseña <span style={{ color: 'red' }}> *</span></label>
                                        <span
                                            className="toggle-password"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </div>
                                    <div className="form-group-register form-group-register-passwordConfirmation" aria-label='Repita su contraseña'>
                                        <input
                                            type={passwordVisible2 ? 'text' : 'password'}
                                            id="passwordConfirmation"
                                            placeholder=" "
                                            value={passwordConfirmation}
                                            autoComplete='new-password'
                                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                                        />
                                        <label htmlFor="password" className="floating-label">Repita la contraseña <span style={{ color: 'red' }}> *</span></label>
                                        <span
                                            className="toggle-password"
                                            onClick={togglePasswordVisibility2}
                                        >
                                            {passwordVisible2 ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </div>

                                    <div className="form-group-register-checkbox" aria-label='Checkbox confirmación términos y condiciones y políticas de privacidad'>
                                        <div className='checkbox-acepta'>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                    style={{ marginRight: '10px' }}
                                                />
                                                He leído y acepto los{' '}
                                                <a href="/terminos-condiciones" target="_blank" style={{ color: '#007bff' }} aria-label='Enlace a los términos y condiciones de Dream Fly'>
                                                    términos y condiciones
                                                </a>{' '}
                                                y la{' '}
                                                <a href="/politica-privacidad" target="_blank" style={{ color: '#007bff' }} aria-label='Enlace a la política de privacidad de Dream Fly'>
                                                    política de privacidad
                                                </a>.
                                            </label>
                                        </div>
                                    </div>
                                </div>





                                {/* //// WHERE ERRORS ARE SHOWN //// */}
                                {inputError &&
                                    <div className='alert alert-danger alert-register' aria-live='assertive'>
                                        <span>Todos los campos son obligatorios.</span>
                                    </div>}
                                {emailError &&
                                    <div className='alert alert-danger alert-register' aria-live='assertive'>
                                        <span>El email introducido no es válido.</span>
                                    </div>}
                                {registrationError &&
                                    <div className='alert alert-danger alert-register' aria-live='assertive'>
                                        <span>El registro ha fallado. Por favor, inténtelo más tarde.</span>
                                    </div>}
                                {passwordDoesNotMatch &&
                                    <div className='alert alert-danger alert-register' aria-live='assertive'>
                                        <span>Las contraseñas no son iguales.</span>
                                    </div>}
                                {alreadyRegistered &&
                                    <div className='alert alert-danger alert-register' aria-live='assertive'>
                                        <span>Este email ya está registrado.</span>
                                    </div>}

                                {isNotChecked &&
                                    <div className='alert alert-danger alert-register' aria-live='assertive'>
                                        <span>Debes aceptar los términos y condiciones y la política de privacidad.</span>
                                    </div>}
                                {serverError &&
                                    <div className='alert alert-danger alert-register' aria-live='assertive'>
                                        <span>Error del servidor. Por favor, inténtelo más tarde.</span>
                                    </div>}


                                <div className="register-actions" >
                                    <button className="signin-btn" onClick={handleRegistrar} aria-label='Botón registrarse'>
                                        <div>
                                            {thinking ? (
                                                <div className="puntos-animados-loading">
                                                    <div className="puntos"></div>
                                                    <div className="puntos" style={{ animationDelay: '0,2s' }}></div>
                                                    <div className="puntos" style={{ animationDelay: '0.4s' }}></div>
                                                </div>
                                            ) : (
                                                <span>Registrarse</span>
                                            )
                                            }
                                        </div>
                                    </button>
                                    <div className="or-separator">
                                        <span>o</span>
                                    </div>
                                </div>
                                <p className="register-prompt" aria-label='Ya tienes una cuenta. Inicia sesión'>
                                    ¿Ya tienes cuenta? <a href="/login" aria-label='Enlace a iniciar sesión'>Inicia sesión</a>
                                </p>
                            </form>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="container-backleft-register">
                        <div className="container-overlay-left-register">
                            <div className="container-img-left-register" />
                            <div className="container-titulo-left-register">
                                <label className="titulo-register">¿Tienes un viaje en mente?</label>
                                <label className="slogan-register">Únete a Dream Fly</label>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default RegisterComponent;
