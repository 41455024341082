import React, { useEffect, useState, useRef, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import LoadingAnimation from '../Loading/loadinganimation';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Función para crear un nuevo icono con color y número
const createCustomIcon = (number, color) => {
  return new L.DivIcon({
    html: `<div style="background-color:${color};width:40px;height:40px;border-radius:50%;display:flex;align-items:center;justify-content:center;color:white;font-size:16px;font-weight:bold;">${number}</div>`,
    className: 'custom-icon',
    iconSize: [30, 30],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });
};

// Función para crear un nuevo icono con color y número
const createCustomIconAccommodation = (color) => {
  return new L.DivIcon({
    html: `<div style="background-color:${color};width:40px;height:40px;border-radius:50%;display:flex;align-items:center;justify-content:center;color:white;font-size:16px;font-weight:bold;">🏠</div>`,
    className: 'custom-icon',
    iconSize: [30, 30],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });
};

// Colores para los días
const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A8', '#FF8C33', '#33FFC1', '#F333FF'];

const MapComponent = ({ accommodation, days, destination_lat, destination_lon, activeMarker, onMarkerClick }) => {
  const defaultPosition = [destination_lat, destination_lon];
  const [loading, setLoading] = useState(true);
  const mapRef = useRef();

  const markers = useMemo(() => {
    const newMarkers = [];

    const accommodation_position = accommodation.lat && accommodation.lon ? [accommodation.lat, accommodation.lon] : null;
    if (accommodation_position) {
      const icon = createCustomIconAccommodation('white');
      newMarkers.push({ name: accommodation.name, position: accommodation_position, icon, activity: null });
    }

    days.forEach((day, dayIndex) => {
      day.activities.forEach((activity, activityIndex) => {
        const position = activity.directions.lat && activity.directions.lon ? [activity.directions.lat, activity.directions.lon] : null;
        if (position) {
          const icon = createCustomIcon(activityIndex + 1, colors[dayIndex % colors.length]);
          newMarkers.push({ name: activity.name, position, icon, activity });
        }
      });
    });

    setLoading(false);
    return newMarkers;
  }, [accommodation, days]);

  useEffect(() => {
    if (activeMarker && mapRef.current) {
      mapRef.current.flyTo(activeMarker.position, 15, { animate: true, duration: 1.5 });
    }
  }, [activeMarker]);

  const CustomMarker = ({ marker }) => {
    const map = useMap();
    const markerRef = useRef();

    useEffect(() => {
      if (activeMarker && activeMarker.name === marker.name) {
        map.flyTo(marker.position, 17, { animate: true, duration: 1.5 });
        markerRef.current.openPopup();
      }
    }, [activeMarker, marker, map]);

    return (
      <Marker
        position={marker.position}
        icon={marker.icon}
        ref={markerRef}
        eventHandlers={{
          click: () => {
            if (onMarkerClick && marker.activity) {
              onMarkerClick(marker.activity);
            }
          },
        }}
      >
        <Popup>
          <div className="popup-content">
            <h4 className="popup-title">{marker.name}</h4>
          </div>
        </Popup>
      </Marker>
    );
  };

  return (
    <MapContainer center={defaultPosition} zoom={10} style={{ height: "100vh", width: "100%" }} preferCanvas={true} whenCreated={mapInstance => { mapRef.current = mapInstance }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {loading ? (<LoadingAnimation />)
        :
        (<>
          {markers.map((marker, index) => (
            <CustomMarker key={index} marker={marker}/>
          ))}</>)
      }
    </MapContainer>
  );
};

export default MapComponent;
