import React, { useEffect } from 'react';
import './overlay-alquiler.css';

const OverlayAlquiler = (props) => {

    useEffect(() => {
        // Añadir clase para bloquear el scroll
        document.body.classList.add('no-scroll');

        return () => {
            // Remover clase al desmontar el componente
            document.body.classList.remove('no-scroll');
        };
    }, []);


    const handleClick = () => {
        props.handleBuyAlquiler();
    };

    return (
        <div className="overlay-alquiler" aria-label='Pop up reserva alquiler de coche'>
            <div className="overlay-alquiler-close" onClick={handleClick} aria-label='Botón cerrar reserva de alquiler de coche'>
                <i className="fas fa-times">   Cerrar reserva</i>
            </div>
            <div className="overlay-alquiler-content" aria-label='Widget para alquilar coche'>
                <object data="https://widgets.rentcars.com/widget-v1.html?requestor=8587&locale=es&utm_source=dreamfly.es&utm_medium=afiliado-widget" width="500" height="500" ></object>
            </div>
        </div>
    );
};

export default OverlayAlquiler;
