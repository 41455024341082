import React, { useEffect } from 'react';
import './overlay-alerta.css';

const OverlayAlerta = (props) => {

    useEffect(() => {
        // Añadir clase para bloquear el scroll
        document.body.classList.add('no-scroll');
        
        return () => {
            // Remover clase al desmontar el componente
            document.body.classList.remove('no-scroll');
        };
    }, []);


    const handleClick = () => {
        window.location.href = props.redirect;
    };

    return (
        <div className="overlay-alerta" aria-label='Alerta Pop Up'>
            <div className="overlay-alerta-content">
                <h2>{props.titulo}</h2>
                <p>{props.subtitulo}</p>
                {props.redirect && <button onClick={handleClick}>{props.btnMensaje}</button>}
            </div>
        </div>
    );
};

export default OverlayAlerta;
