import React from 'react';
import GTGBtn from './gtg-btn.png';
import TiqetsBtn from './tiqets-logo.png';
import './activity-details.css';

const ActivityDetails = ({ activity, handleCloseActivity, destination }) => {
    if (!activity) return null;

    const handleClickGM = () => {
        window.open(activity.details.links.google_maps, '_blank');
    }

    const handleClickWebsite = () => {
        window.open(activity.details.links.website, '_blank');
    }

    const handleGYG = () => {
        window.open(`https://www.getyourguide.com/s/?q=${destination}&partner_id=XV0OM7L`, '_blank');
    }

    const handleTiqets = () => {
        window.open(`https://www.tiqets.com/es/search?q=${destination}&partner=dream_fly-165549`, '_blank');
    }

    return (
        <>
            <div className="close-activity-details" aria-label='Pop up detalles de la actividad'>
                <button onClick={handleCloseActivity} aria-label='Cerrar actividad'>
                    <span className='fa fa-times'></span>
                </button>
            </div>
            <div className="activity-details-container" aria-label='Descripción de actividad'>
                <h2>{activity.name}</h2>
                <p>{activity.directions.address}</p>
                <p>{activity.description}</p>
                <div className="booking-options" aria-label='Opciones para reserva de actividades'>
                    <h3>Reservas de actividades</h3>
                    <div className='booking-btns' aria-label='Opciones'>
                        <button className="booking-button" onClick={handleGYG} aria-label='Reservar actividades en Get Your Guide'><img src={GTGBtn} alt='Get your Guide - Reserva de actividades'></img></button>
                        <button className="booking-button btn-viator" onClick={handleTiqets} aria-label='Reservar actividades en Tiqets'><img src={TiqetsBtn} alt='Tiqets - Reserva de actividades'></img></button>
                    </div>
                </div>
                {activity.details.tips &&
                    <div className="activity-tips" aria-label='Tips para las actividades'>
                        <h3>Tips</h3>
                        <ul aria-label='Listado de tips para las actividades'>
                            {activity.details.tips.tip1 && <li aria-label='Tip 1'>{activity.details.tips.tip1}</li>}
                            {activity.details.tips.tip2 && <li aria-label='Tip 2'>{activity.details.tips.tip2}</li>}
                        </ul>
                    </div>
                }
                {activity.details.links &&
                    <div className="activity-details-links" aria-label='Links para las actividades'>
                        <h3>Links</h3>
                        {activity.details.links.google_maps && <button className='btn-activity-details-gm' onClick={handleClickGM} aria-label='Link a Google Maps'> <span className='fab fa-google'> Maps</span> </button>}
                        {activity.details.links.website && <button className='btn-activity-details-gm' onClick={handleClickWebsite} aria-label='Link a su página web'> <span className='fa fa-globe'> Web</span></button>}
                    </div>
                }
            </div>
        </>
    );
};

export default ActivityDetails;
