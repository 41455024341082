import React, { useState, useEffect } from 'react';
import CitySelector from '../City-select/CitySelect';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import SessionExpiredOverlay from '../Session-expired/session-expired-overlay';
import OverlayAlerta from '../OverlayAlerta/overlay-alerta';
import OverlayAnuncio from '../OverlayAnuncio/overlay-anuncio';
import OverlayErrorCreando from '../OverlayErrorCreando/overlay-error-creando';
import './create.css';

const CACHE_KEY = 'mis-viajes-ids';
const CACHE_EXPIRATION_MS = 10 * 60 * 1000; // 10 minutos

const getFromCache = () => {
    const cachedData = sessionStorage.getItem(CACHE_KEY);
    if (!cachedData) return null;

    const { timestamp, data } = JSON.parse(cachedData);
    if (Date.now() - timestamp > CACHE_EXPIRATION_MS) {
        sessionStorage.removeItem(CACHE_KEY);
        return null;
    }

    return data;
};

const saveToCache = async (ids) => {
    const cacheData = {
        timestamp: Date.now(),
        data: ids,
    };
    sessionStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
};

const openDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('miBaseDeDatos', 1);
        request.onerror = (event) => reject(event);
        request.onsuccess = (event) => resolve(event.target.result);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('viajes')) {
                db.createObjectStore('viajes', { keyPath: '_id' });
            }
        };
    });
};

const fetchTripFromIndexedDB = async (id) => {
    const db = await openDatabase();
    return new Promise((resolve) => {
        const transaction = db.transaction('viajes', 'readonly');
        const objectStore = transaction.objectStore('viajes');
        const request = objectStore.get(id);
        request.onsuccess = () => resolve(request.result);
    });
};

const saveTripToIndexedDB = async (trip) => {
    const db = await openDatabase();
    const transaction = db.transaction('viajes', 'readwrite');
    const objectStore = transaction.objectStore('viajes');
    objectStore.put(trip);
};

const fetchTripFromAPI = async (id, token) => {
    try {
        const response = await fetch(`https://dreamfly.es/api/get-trip/${id}`, {
            headers: { 'x-access-token': token }
        });
        const data = await response.json();
        if (response.ok) {
            await saveTripToIndexedDB(data.trip); // Guardar en IndexedDB
            return data.trip;
        } else if (data.message === 'Token expired') {
            return 'Token expired';
        } else {
            console.log('Algo ocurre (fetch trip from API)');
            return null;
        }
    } catch (err) {
        console.error('Error:', err);
        return null;
    }
};

function Create(props) {
    const [origen, setOrigen] = useState('');
    const [destino, setDestino] = useState('');
    const [selectedPersons, setSelectedPersons] = useState('');
    const [dias, setDias] = useState();
    const [selectedBudget, setSelectedBudget] = useState('');
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [preferenciasDieteticas, setPreferenciasDieteticas] = useState('');
    const [tipoAlojamiento, setTipoAlojamiento] = useState('');
    const [selectedStars, setSelectedStars] = useState('');
    const [selectedUbicationHotel, setSelectedUbicationHotel] = useState('');
    const [specialNeeds, setSpecialNeeds] = useState('');
    const [transportType, setTransportType] = useState('');
    const [isFormComplete, setIsFormComplete] = useState(false);

    const [isCreating, setIsCreating] = useState(false);
    const [sessionExpired, setSessionExpired] = useState(false);
    const [failedCreating, setFailedCreating] = useState(false);
    const [successCreating, setSuccessCreating] = useState(false);

    useEffect(() => {
        checkFormCompletion();
    }, [origen, destino, selectedPersons, dias, selectedBudget, selectedInterests, tipoAlojamiento, selectedStars, selectedUbicationHotel, transportType]);

    useEffect(() => {
        if (!props.token) {
            setSessionExpired(true);
        }
    }, [props.token]);

    const handleSetOrigen = (o) => {
        if (o === null) {
            setOrigen('');
        } else {
            setOrigen(o.label);
            console.log('ORIGEN: ' + o.label);
        }
    }
    const handleSetDestino = (d) => {
        if (d === null) {
            setDestino('');
        } else {
            setDestino(d.label);
            console.log('DESTINO: ' + d.label);
        }
    }

    const handleSetDias = (d) => {
        setDias(d.target.value);
    }

    const handleBudgetClick = (budget) => {
        setSelectedBudget(budget);
    }

    const handleInterestClick = (interest) => {
        setSelectedInterests(prevInterests =>
            prevInterests.includes(interest)
                ? prevInterests.filter(i => i !== interest)
                : [...prevInterests, interest]
        );
    }

    const handlePreferenciasDieteticasChange = (e) => {
        setPreferenciasDieteticas(e.target.value);
    }

    const handleTipoAlojamientoClick = (accommodation) => {
        setTipoAlojamiento(accommodation);
    }

    const handleStarsClick = (stars) => {
        setSelectedStars(stars);
    }

    const handlePersonsClick = (persons) => {
        setSelectedPersons(persons);
    }

    const handleUbicationHotelClick = (ubication) => {
        setSelectedUbicationHotel(ubication);
    }

    const handleSpecialNeedsChange = (e) => {
        setSpecialNeeds(e.target.value);
    }

    const handleTransportTypeChange = (e) => {
        setTransportType(e);
    }

    const checkFormCompletion = () => {
        if (
            origen &&
            destino &&
            dias &&
            dias > 0 && dias <= 7 &&
            selectedBudget &&
            selectedInterests.length > 0 &&
            tipoAlojamiento &&
            selectedStars &&
            selectedPersons &&
            selectedUbicationHotel &&
            transportType
        ) {
            setIsFormComplete(true);
        } else {
            setIsFormComplete(false);
        }
    }

    const handleCreateTravel = async () => {
        if (isFormComplete) {
            console.log("Se va a crear el viaje");
            setIsCreating(true);
            try {

                const response = await fetch("https://dreamfly.es/api/crear-viaje", {
                    method: "POST",
                    headers: {
                        "x-access-token": props.token,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        origen: origen,
                        destino: destino,
                        quienViaja: selectedPersons,
                        duracion: dias,
                        presupuesto: selectedBudget,
                        intereses: selectedInterests,
                        tipoAlojamiento: tipoAlojamiento,
                        estrellasAlojamiento: selectedStars,
                        ubicacionAlojamiento: selectedUbicationHotel,
                        necesidadesEspecialesAlojamiento: specialNeeds,
                        preferenciasDieteticas: preferenciasDieteticas,
                        tipoTransporte: transportType,
                    }),
                });

                const data = await response.json();
                if (response.ok) {
                    if (data.viaje_id) {
                        setIsCreating(false);
                        setSuccessCreating(true);
                        const id = data.viaje_id;
                        await handleTripsCache(id);
                    } else {
                        console.log('Algo ocurre (create ok en else)');
                    }
                } else if (data.message === 'Token expired') {
                    setIsCreating(false);
                    setSessionExpired(true);
                } else if (data.message === 'Failed creating itinerary') {
                    setIsCreating(false);
                    setFailedCreating(true);
                }
                else {
                    setIsCreating(false);
                    setFailedCreating(true);
                    console.log('Algo ocurre (create mal en else)');
                }
            } catch (error) {
                console.log('Error:', error);
            }
        } else {
            // TODO: Fill this with an error alert cuz the form is not filled completely.
            console.log('Formulario incompleto');
        }
    }

    const handleTripsCache = async (id_guardar) => {
        const cachedIds = getFromCache();
        if (cachedIds) {
            const newIds = [...cachedIds, id_guardar];
            saveToCache(newIds);
            let trip = await fetchTripFromAPI(id_guardar, props.token);
            saveTripToIndexedDB(trip);
        } else {
            try {
                const response = await fetch('https://dreamfly.es/api/my-trips', {
                    headers: { 'x-access-token': props.token }
                });
                const data = await response.json();
                if (response.ok) {
                    const tripIds = data.trips;
                    saveToCache(tripIds);

                    await Promise.all(tripIds.map(async (id) => {
                        let trip = await fetchTripFromIndexedDB(id);
                        if (!trip) {
                            trip = await fetchTripFromAPI(id, props.token);
                            if (trip === 'Token expired') {
                                setSessionExpired(true);
                                return null;
                            }
                        }
                        return trip;
                    }));
                } else if (data.message === 'Token expired') {
                    setSessionExpired(true);
                } else {
                    console.log('Algo ocurre (my-trips en create else)');
                }
            } catch (err) {
                console.log('Error:', err);
            }
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} placement="top">
            <div>
                <p>Se elegirán las actividades y los restaurantes en función de la categoría seleccionada</p>
            </div>
        </Tooltip>
    );

    const handleClickErrorCreando = () => {
        setFailedCreating(false);
        handleCreateTravel();
    }

    return (
        <div className='intro-create'>
            {sessionExpired && <SessionExpiredOverlay logout={props.logout} />}
            {failedCreating && <OverlayErrorCreando handleClick={handleClickErrorCreando} />}
            {successCreating && <OverlayAlerta titulo='¡Itinerario creado con éxito!' subtitulo='Su itinerario ha sido creado con éxito. Puede verlo en la sección de "Mis viajes".' redirect='https://dreamfly.es/mis-viajes' btnMensaje='Ver mis viajes' />}
            {isCreating && <OverlayAnuncio />}
            <div className="overlay-create" aria-label='Crear tu viaje con Dream Fly'>
                <div className='container-titulo' aria-label='Titulo crear tu viaje'>
                    <h1>Planifica tu viaje con nosotros</h1>
                    <h2>El viaje de tus sueños en un par de minutos</h2>
                </div>
                <div className="flex-container-create">
                    <div className="flex-item1" aria-label='Requisitos del viaje'>
                        <h2>Requisitos del viaje</h2>
                        <hr className='salto-linea' />
                        <div className='alert alert-info' style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }} aria-label='Advertencia campos obligatoriosaria-live="assertive"'>
                            <span style={{ flexGrow: 1, width: '100%' }}>Los campos con asterisco (<span style={{ color: 'red' }}>*</span>) son obligatorios.</span>
                        </div>
                        <div className="origenYDestino" aria-label='Input de origen y destino del viaje'>
                            <div className="origen" aria-label='Input origen'>
                                <h3><span className='fa fa-plane-departure' style={{ marginRight: '10px' }}></span>Elige el punto de partida<span style={{ color: 'red' }}> *</span></h3>
                                <CitySelector handleSetDestino={handleSetOrigen} />
                            </div>
                            <div className="destino" aria-label='Input destino'>
                                <div className="destino">
                                    <h3><span className='fa fa-plane-arrival' style={{ marginRight: '10px' }}></span>Elige tu destino<span style={{ color: 'red' }}> *</span></h3>
                                    <CitySelector handleSetDestino={handleSetDestino} />
                                </div>
                            </div>
                        </div>
                        <div className="dias-personas-budget">
                            <div className="personas" aria-label="Selección tipo de viajero">
                                <h3><span className='fa fa-users' style={{ marginRight: '10px' }}></span>¿Quién viaja?<span style={{ color: 'red' }}> *</span></h3>
                                <div className="personas-options">
                                    <button
                                        type="button"
                                        className={selectedPersons === 'Solo' ? 'selected' : ''}
                                        onClick={() => handlePersonsClick('Solo')}
                                        aria-label='Solo'
                                    >
                                        Solo
                                    </button>
                                    <button
                                        type="button"
                                        className={selectedPersons === 'Pareja' ? 'selected' : ''}
                                        onClick={() => handlePersonsClick('Pareja')}
                                        aria-label='Pareja'
                                    >
                                        Pareja
                                    </button>
                                    <button
                                        type="button"
                                        className={selectedPersons === 'Grupo' ? 'selected' : ''}
                                        onClick={() => handlePersonsClick('Grupo')}
                                        aria-label='Grupo'
                                    >
                                        Grupo
                                    </button>
                                    <button
                                        type="button"
                                        className={selectedPersons === 'Familia con hijos' ? 'selected' : ''}
                                        onClick={() => handlePersonsClick('Familia con hijos')}
                                        aria-label='Familia con hijos'
                                    >
                                        Familia con hijos
                                    </button>
                                </div>
                            </div>
                            <div className="dias" aria-label='Input duración del viaje (máximo 7 días)'>
                                <h3><span className='fa fa-calendar' style={{ marginRight: '10px' }}></span>¿Cuántos días?<span style={{ color: 'red' }}> *</span></h3>
                                <input type="number" id="days" value={dias} onChange={handleSetDias} placeholder=" Máximo 7 días" min="1" max="7" />
                            </div>
                            <div className="budget" aria-label='Opciones de presupuesto'>
                                <div className="budget-titulos-container">
                                    <OverlayTrigger
                                        placement="bottom-end"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                        className='tooltip-budget'
                                    >
                                        <div className="budget-information-container" aria-label='Pulsar para obtener información sobre los presupuestos'>
                                            <div className='budget-info'><span className='fas fa-info'></span></div>
                                        </div>
                                    </OverlayTrigger>
                                    <h3><span className='fa fa-euro-sign' style={{ marginRight: '10px' }}></span>Presupuesto<span style={{ color: 'red' }}> *</span></h3>
                                </div>
                                <div className="btn-budget">
                                    <button
                                        type="button"
                                        className={selectedBudget === 'Económico' ? 'selected' : ''}
                                        onClick={() => handleBudgetClick('Económico')}
                                        aria-label='Económico'
                                    >
                                        Económico
                                    </button>
                                    <button
                                        type="button"
                                        className={selectedBudget === 'Medio' ? 'selected' : ''}
                                        onClick={() => handleBudgetClick('Medio')}
                                        aria-label='Medio'
                                    >
                                        Medio
                                    </button>
                                    <button
                                        type="button"
                                        className={selectedBudget === 'Lujoso' ? 'selected' : ''}
                                        onClick={() => handleBudgetClick('Lujoso')}
                                        aria-label='Lujoso'
                                    >
                                        Lujoso
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="tipos-de-actividades"aria-label='Opciones de intereses en el viaje'>
                            <h3><span className='fa fa-map' style={{ marginRight: '10px' }}></span>Intereses en el viaje<span style={{ color: 'red' }}> *</span></h3>
                            <div className="interests-options">
                                {['Aventura', 'Cultura', 'Ocio y bienestar', 'Gastronomía', 'Senderismo', 'Historia', 'Trabajo', 'Naturaleza', 'Ocio nocturno', 'Fotografía', 'Escapada', 'Compras', 'Actividades acuáticas'].map(interest => (
                                    <button
                                        type="button"
                                        key={interest}
                                        className={selectedInterests.includes(interest) ? 'selected' : ''}
                                        onClick={() => handleInterestClick(interest)}
                                        aria-label={interest}
                                    >
                                        {interest}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="necesidades-especiales" aria-label='Preferencias dietéticas (Max 200 caracteres)'>
                            <h3>Preferencias dietéticas (Max 200 caracteres)</h3>
                            <textarea type="text" id="pref_dieteticas" value={preferenciasDieteticas} onChange={handlePreferenciasDieteticasChange} placeholder="Vegano, celiaco, comer algo en concreto..." maxLength="200" />
                        </div>
                    </div>
                    <div className="flex-column">
                        <div className="flex-item2" aria-label='Requisitos de alojamiento'>
                            <h2>Requisitos de alojamiento</h2>
                            <hr className='salto-linea' />
                            <div className="requisitos-alojamiento">
                                <div className="requisitos-options" aria-label='Opciones tipo de alojamiento'>
                                    <h3>¿Qué tipo de alojamiento?<span style={{ color: 'red' }}> *</span></h3>
                                    <div className="requisitos-options-btn">
                                        <button
                                            type="button"
                                            className={tipoAlojamiento === 'Hotel' ? 'selected' : ''}
                                            onClick={() => handleTipoAlojamientoClick('Hotel')}
                                            aria-label='Hotel'
                                        >
                                            Hotel
                                        </button>
                                        <button
                                            type="button"
                                            className={tipoAlojamiento === 'Hostal' ? 'selected' : ''}
                                            onClick={() => handleTipoAlojamientoClick('Hostal')}
                                            aria-label='Hostal'
                                        >
                                            Hostal
                                        </button>
                                        <button
                                            type="button"
                                            className={tipoAlojamiento === 'Apartamento' ? 'selected' : ''}
                                            onClick={() => handleTipoAlojamientoClick('Apartamento')}
                                            aria-label='Apartamento'
                                        >
                                            Apartamento
                                        </button>
                                    </div>
                                </div>
                                <div className="categoria-estrellas" aria-label='Opciones de estrellas del alojamiento'>
                                    <h3>¿Cuántas estrellas?<span style={{ color: 'red' }}> *</span></h3>
                                    <div className="estrellas-options">
                                        <button
                                            type="button"
                                            className={selectedStars === '1' ? 'selected' : ''}
                                            onClick={() => handleStarsClick('1')}
                                            aria-label='1 estrella'
                                        >
                                            1
                                        </button>
                                        <button
                                            type="button"
                                            className={selectedStars === '2' ? 'selected' : ''}
                                            onClick={() => handleStarsClick('2')}
                                            aria-label='2 estrellas'
                                        >
                                            2
                                        </button>
                                        <button
                                            type="button"
                                            className={selectedStars === '3' ? 'selected' : ''}
                                            onClick={() => handleStarsClick('3')}
                                            aria-label='3 estrellas'
                                        >
                                            3
                                        </button>
                                        <button
                                            type="button"
                                            className={selectedStars === '4' ? 'selected' : ''}
                                            onClick={() => handleStarsClick('4')}
                                            aria-label='4 estrellas'
                                        >
                                            4
                                        </button>
                                        <button
                                            type="button"
                                            className={selectedStars === '5' ? 'selected' : ''}
                                            onClick={() => handleStarsClick('5')}
                                            aria-label='5 estrellas'
                                        >
                                            5
                                        </button>
                                    </div>
                                </div>
                                <div className="preferencia-ubicacion" aria-label='Preferencias de la ubicación del alojamiento'>
                                    <h3>Preferencia de ubicación<span style={{ color: 'red' }}> *</span></h3>
                                    <div className="preferencia-ubicacion-btn">
                                        <button
                                            type="button"
                                            className={selectedUbicationHotel === 'Céntrica' ? 'selected' : ''}
                                            onClick={() => handleUbicationHotelClick('Céntrica')}
                                            aria-label='Céntrica'
                                        >
                                            Céntrica
                                        </button>
                                        <button
                                            type="button"
                                            className={selectedUbicationHotel === 'Cerca del aeropuerto' ? 'selected' : ''}
                                            onClick={() => handleUbicationHotelClick('Cerca del aeropuerto')}
                                            aria-label='Cerca del aeropuerto'
                                        >
                                            Cerca del aeropuerto
                                        </button>
                                        <button
                                            type="button"
                                            className={selectedUbicationHotel === 'Cerca de la playa' ? 'selected' : ''}
                                            onClick={() => handleUbicationHotelClick('Cerca de la playa')}
                                            aria-label='Cerca de la playa'
                                        >
                                            Cerca de la playa
                                        </button>
                                        <button
                                            type="button"
                                            className={selectedUbicationHotel === 'Cerca de zonas turísticas' ? 'selected' : ''}
                                            onClick={() => handleUbicationHotelClick('Cerca de zonas turísticas')}
                                            aria-label='Cerca de zonas turísticas'
                                        >
                                            Cerca de zonas turísticas
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="necesidades-especiales" aria-label='Necesidades especiales en el alojamiento'>
                                <h3>Necesidades especiales (Max 200 caracteres)</h3>
                                <textarea type="text" id="necesidades" value={specialNeeds} onChange={handleSpecialNeedsChange} placeholder="Accesibilidad, tipos de habitaciones..." maxLength="200" />
                            </div>
                        </div>
                        <div className="flex-item3">
                            <div className="requisitos-transporte" aria-label='Requisitos de transporte'>
                                <h2>Requisitos de transporte</h2>
                                <hr className='salto-linea' />
                                <div className="requisitos-transporte-cosa" aria-label='Opciones de tipo de transporte'>
                                    <div className="tipo-transporte">
                                        <h3>Tipo de transporte<span style={{ color: 'red' }}> *</span></h3>
                                        <div className="transporte-options-btn">
                                            <button
                                                type="button"
                                                className={transportType === 'Avion' ? 'selected' : ''}
                                                onClick={() => handleTransportTypeChange('Avion')}
                                                aria-label='Avión'
                                            >
                                                Avión
                                            </button>
                                            <button
                                                type="button"
                                                className={transportType === 'Tren' ? 'selected' : ''}
                                                onClick={() => handleTransportTypeChange('Tren')}
                                                aria-label='Tren'
                                            >
                                                Tren
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-enviar">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="button-tooltip">
                                {!isFormComplete ? 'Completa todos los campos' : 'Puede tardar hasta un par de minutos. Por favor, espere.'}
                            </Tooltip>
                        }
                    >
                        <span className="d-inline-block">
                            <button
                                type="button"
                                className={!isFormComplete ? 'disabled' : ''}
                                disabled={!isFormComplete}
                                onClick={handleCreateTravel}
                            >
                                Crear viaje
                            </button>
                        </span>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    )
};

export default Create;
