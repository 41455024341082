import React, { useState, useRef } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

const CitySelector = ({ handleSetDestino }) => {
  const [inputValue, setInputValue] = useState('');
  const timeoutRef = useRef(null);

  const loadOptions = async (searchQuery) => {
    if (!searchQuery) {
      return {
        options: [],
        hasMore: false,
      };
    }

    try {
      const response = await fetch(
        `https://dreamfly.es/api/geonames/${searchQuery}`
      );

      const data = await response.json();

      if (data.options) {
        return {
          options: data.options,
          hasMore: false,
        };
      } else {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const debouncedLoadOptions = (inputValue) => {
    if(inputValue === '') {
      return Promise.resolve({ options: [], hasMore: false});
    }
    return new Promise((resolve) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(async () => {
        const result = await loadOptions(inputValue.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
        resolve(result);
      }, 2000);
    });
  };

  const handleCityChange = (selectedOption) => {
    handleSetDestino(selectedOption);
    setInputValue('');
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  return (
    <AsyncPaginate
      placeholder="Selecciona una ciudad"
      loadOptions={debouncedLoadOptions}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      onChange={handleCityChange}
      noOptionsMessage={() => "No hay resultados"}
      isClearable={true}
    />
  );
};

export default CitySelector;
