import React, { useEffect } from 'react';
import './session-expired-overlay.css';

const SessionExpiredOverlay = (props) => {

    useEffect(() => {
        // Añadir clase para bloquear el scroll
        document.body.classList.add('no-scroll');
        
        return () => {
            // Remover clase al desmontar el componente
            document.body.classList.remove('no-scroll');
        };
    }, []);


    const handleLoginRedirect = () => {
        props.logout();
    };

    return (
        <div className="session-expired-overlay" aria-label='Alerta sesión expirada'>
            <div className="session-expired-overlay-content">
                <h2>Sesión Caducada</h2>
                <p>Tu sesión ha caducado. Por favor, inicia sesión de nuevo.</p>
                <button onClick={handleLoginRedirect} aria-label='Botón iniciar sesión'>Iniciar Sesión</button>
            </div>
        </div>
    );
};

export default SessionExpiredOverlay;
