import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigation } from '../../components/Navbar/navigation';
import TravelSwipe from '../../components/Explore/travelswipe';

function Explorar(props) {
  return (
    <>
      <Helmet>
        <title>Explora y descubre viajes con Travel Swipe - Dream Fly</title>
        <meta name="description" content="Explora diferentes viajes con Travel Swipe de Dream Fly. Descubre nuevos destinos y da like o dislike según tus preferencias." />
        <meta name="keywords" content="explorar viajes, Travel Swipe, Dream Fly, descubrir destinos, descubrir viajes, no se donde viajar, elegir viajes, like dislike viajes, no se a donde viajar" />
      </Helmet>
      <div>
        <Navigation token={props.token} logout={props.logout}/>
        <TravelSwipe token={props.token} logout={props.logout}/>
      </div>
    </>
  )
}

export default Explorar;
