import React, { useEffect, useState } from 'react';
import ProfileDetails from './profile-details';
import SessionExpiredOverlay from '../Session-expired/session-expired-overlay';
import './profile.css';

const CACHE_KEY = 'dislike-trips-ids';
const CACHE_EXPIRATION_MS = 10 * 60 * 1000; // 10 minutos

const getFromCache = () => {
  const cachedData = sessionStorage.getItem(CACHE_KEY);
  if (!cachedData) return null;

  const { timestamp, data } = JSON.parse(cachedData);
  if (Date.now() - timestamp > CACHE_EXPIRATION_MS) {
    sessionStorage.removeItem(CACHE_KEY);
    return null;
  }

  return data;
};

const saveToCache = (ids) => {
  const cacheData = {
    timestamp: Date.now(),
    data: ids,
  };
  sessionStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
};

const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('miBaseDeDatos', 1);
    request.onerror = (event) => reject(event);
    request.onsuccess = (event) => resolve(event.target.result);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('viajes')) {
        db.createObjectStore('viajes', { keyPath: '_id' });
      }
    };
  });
};

const saveTripToIndexedDB = async (trip) => {
  const db = await openDatabase();
  const transaction = db.transaction('viajes', 'readwrite');
  const objectStore = transaction.objectStore('viajes');
  objectStore.put(trip);
};

const fetchTripFromIndexedDB = async (id) => {
  const db = await openDatabase();
  return new Promise((resolve) => {
    const transaction = db.transaction('viajes', 'readonly');
    const objectStore = transaction.objectStore('viajes');
    const request = objectStore.get(id);
    request.onsuccess = () => resolve(request.result);
  });
};

const fetchTripFromAPI = async (id, token) => {
  try {
    const response = await fetch(`https://dreamfly.es/api/get-trip/${id}`, {
      headers: { 'x-access-token': token }
    });
    const data = await response.json();
    if (response.ok) {
      await saveTripToIndexedDB(data.trip); // Guardar en IndexedDB
      return data.trip;
    } else if (data.message === 'Token expired') {
      return 'Token expired';
    } else {
      console.log('Algo ocurre (fetch trip from API)');
      return null;
    }
  } catch (err) {
    console.error('Error:', err);
    return null;
  }
};

const Profile = (props) => {
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [dislikes, setDislikes] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const cachedIds = getFromCache();
      if (cachedIds) {
        const tripsData = await Promise.all(cachedIds.map(async (id) => {
          let trip = await fetchTripFromIndexedDB(id);
          if (!trip) {
            trip = await fetchTripFromAPI(id, props.token);
            if (trip === 'Token expired') {
              setSessionExpired(true);
              return null;
            }
          }
          return trip;
        }));
        setDislikes(tripsData.filter(trip => trip !== null));
      } else {
        try {
          const response = await fetch('https://dreamfly.es/api/my-dislikes-trips', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': props.token
            }
          });
          const data = await response.json();
          if (response.ok) {
            const tripIds = data.trips; // Aquí data.trips ya contiene solo los IDs
            if (tripIds.length > 0) {
              saveToCache(tripIds);

              const tripsData = await Promise.all(tripIds.map(async (id) => {
                let trip = await fetchTripFromIndexedDB(id);
                if (!trip) {
                  trip = await fetchTripFromAPI(id, props.token);
                  if (trip === 'Token expired') {
                    setSessionExpired(true);
                    return null;
                  }
                }
                return trip;
              }));

              setDislikes(tripsData.filter(trip => trip !== null));
            } else {
              setDislikes([]);
            }
          } else if (data.message === 'Token expired') {
            setSessionExpired(true);
          } else {
            console.log("Algo ocurre (profile else)");
          }
        } catch (error) {
          console.log('Error: ' + error);
        }
      }

      if (!props.token) {
        window.location.href = '/login';
      } else {
        try {
          const response = await fetch('https://dreamfly.es/api/get-profile', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': props.token
            }
          });
          const data = await response.json();
          if (response.ok) {
            setUsername(data.username);
            setEmail(data.email);
          } else if (data.message === 'Token expired') {
            setSessionExpired(true);
          } else {
            console.log("Algo ocurre (profile else)");
          }
        } catch (error) {
          console.log('Error: ' + error);
        }
      }
    };
    fetchData();
  }, [props.token]);

  return (
    <div className="profile">
      {sessionExpired && <SessionExpiredOverlay logout={props.logout} />}
      <div className="content">
        <ProfileDetails username={username} email={email} trips={dislikes} token={props.token} logout={props.logout} />
      </div>
      <button className="profile-logout" onClick={props.logout}>Cerrar Sesión</button>
    </div>
  );
};

export default Profile;
