import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigation } from '../../components/Navbar/navigation';
import Trips from '../../components/My-trips/trips';
import Contact from '../../components/Contact/contact';

function MyTrips(props) {
  return (
    <>
      <Helmet>
        <title>Mis Viajes - Dream Fly</title>
        <meta name="description" content="Accede a todos tus viajes planificados en Dream Fly. Revisa tus itinerarios personalizados en cualquier momento." />
        <meta name="keywords" content="mis viajes, viajes planificados, itinerarios personalizados, crear viajes con IA, Dream Fly" />
      </Helmet>
      <div>
        <Navigation token={props.token} logout={props.logout}/>
        <Trips token={props.token} logout={props.logout} />
        <Contact data={props.landingPageData}/>
      </div>
    </>
  )
}

export default MyTrips;
