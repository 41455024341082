import React from "react";
import Button from "react-bootstrap/Button";

export const Navigation = (props) => {

  const handleMiPerfil = () => {
    window.location.href = "/mi-perfil";
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top" aria-label="Barra de navegación">
      <div className="container-navbar">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >

            <span className="sr-only">Cambiar navegacion</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="/" aria-label="Logo Dream Fly - Crea tu viaje personalizado">
            Dream Fly
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/crear-viaje" className="crear-viaje" aria-label="Crear un viaje personalizado">
                Crea un viaje
              </a>
            </li>
            <li>
              <a href="/mis-viajes" className="mis-viajes" aria-label="Mis viajes">
                Mis viajes
              </a>
            </li>
            <li>
              <a href="/explorar-destinos" className="explorar-destinos" aria-label="Travel Swipe">
                Travel swipe
              </a>
            </li>
            <li>
              <a href="/mis-favoritos" className="mis-favoritos" aria-label="Mis viajes favoritos">
                Mis favoritos
              </a>
            </li>

            {props.token ? (
              <div className="container-btnPerfil" style={{ display: 'flex', marginTop: '1.7%', marginLeft: '4%' }}>
                <li className="justify-content-center align-items-center text-align-center">
                  <Button className="logout" onClick={handleMiPerfil} aria-label="Botón mi perfil">
                    Mi perfil
                  </Button>
                </li>
              </div>
            ) : <div className="container-btnPerfil" style={{ display: 'flex', marginTop: '1.7%', marginLeft: '4%' }}>
              <li className="justify-content-center align-items-center text-align-center">
                <Button href="/login" aria-label="Botón Iniciar sesión">Iniciar sesión</Button>
              </li>
            </div>}

          </ul>

        </div>
      </div >
    </nav >
  );
};
