import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './trip.css';

function Trip({ trip, swipeMode, handleLike, handleDislike }) {
    const handleLikeClick = () => {
        handleLike();
    };

    const handleDislikeClick = () => {
        handleDislike();
    };

    const handleCardClick = () => {
        if (!swipeMode) {
            window.location.href = `/viaje/${trip._id}`;
        }
    };
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} placement="top">
            <div aria-label='Información adicional sobre el viaje'>
                <p><strong>Viajantes:</strong> {trip.trip.traveler}</p>
                <p><strong>Presupuesto:</strong> {trip.trip.budget_category}</p>
                <p><strong>Intereses:</strong> {trip.trip.interests}</p>
                <p><strong>Alojamiento:</strong> {trip.trip.accommodation.type}</p>
                <p><strong>Transporte:</strong> {trip.trip.transport.type}</p>
            </div>
        </Tooltip>
    );

    return (
        <div className="mytrips-card-container">
            {trip && (
                <div className="mytrips-card" onClick={handleCardClick} aria-label='Carta de un viaje (es clicable)'>
                    <div className="mytrips-avatar" onClick={(e) => e.stopPropagation()}>
                        <div className="avatar-initials"><span className='fas fa-map-pin'></span></div>
                        <div className="avatar-username" aria-label='Destino del viaje'>{trip.trip.destination}</div>
                    </div>
                    <OverlayTrigger
                        placement="bottom-end"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <div className="mytrips-avatar4" onClick={(e) => e.stopPropagation()}>
                            <div className='avatar-initials-info' aria-label='Información adicional del viaje'><span className='fas fa-info'></span></div>
                        </div>
                    </OverlayTrigger>
                    <div className={`mytrips-avatar2 ${swipeMode ? 'swipe-mode' : ''}`} onClick={(e) => e.stopPropagation()}>
                        <div className="avatar-initials"><span className='fas fa-euro-sign'></span></div>
                        <div className="avatar-username" aria-label='Coste total del viaje por persona'>{trip.total_expenses_per_person}</div>
                    </div>
                    <div className={`mytrips-avatar3 ${swipeMode ? 'swipe-mode' : ''}`} onClick={(e) => e.stopPropagation()}>
                        <div className="avatar-initials"><span className='fas fa-calendar'></span></div>
                        <div className="avatar-username" aria-label='Duración del viaje'>{trip.trip.duration} días</div>
                    </div>

                    {swipeMode && (
                        <div className="mytrips-card-actions" onClick={(e) => e.stopPropagation()} aria-label='Botones para travel swipe'>
                            <button className="mytrips-card-action-dislike" onClick={handleDislikeClick} aria-label='Botón para dar dislike al viaje'>
                                <span className='fa fa-times btn-dislike'></span>
                            </button>
                            <button className="mytrips-card-action-like" onClick={handleLikeClick} aria-label='Botón para dar like al viaje'>
                                <span className='fa fa-heart btn-like'></span>
                            </button>
                        </div>
                    )}
                    <img src={trip.URL_image} alt="Imagen destino viaje Travel Swipe - Dream Fly" />
                </div>
            )}
            {!swipeMode && (
                <div className="mytrips-card-text" aria-label='Titulo del viaje'>
                    <h2><a href={`/viaje/${trip._id}`} aria-label='Enlace al viaje'>{trip.trip.title}</a></h2>
                </div>
            )}
        </div>
    );
}

export default Trip;
