import React, { useState, useEffect } from 'react';
import Trip from '../Trip/trip';
import LoadingAnimation from '../Loading/loadinganimation';
import SessionExpiredOverlay from '../Session-expired/session-expired-overlay';
import './trips.css';

const CACHE_KEY = 'mis-viajes-ids';
const CACHE_EXPIRATION_MS = 10 * 60 * 1000; // 10 minutos

const getFromCache = () => {
    const cachedData = sessionStorage.getItem(CACHE_KEY);
    if (!cachedData) return null;

    const { timestamp, data } = JSON.parse(cachedData);
    if (Date.now() - timestamp > CACHE_EXPIRATION_MS) {
        sessionStorage.removeItem(CACHE_KEY);
        return null;
    }

    return data;
};

const saveToCache = (ids) => {
    const cacheData = {
        timestamp: Date.now(),
        data: ids,
    };
    sessionStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
};

const openDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('miBaseDeDatos', 1);
        request.onerror = (event) => reject(event);
        request.onsuccess = (event) => resolve(event.target.result);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('viajes')) {
                db.createObjectStore('viajes', { keyPath: '_id' });
            }
        };
    });
};

const saveTripToIndexedDB = async (trip) => {
    const db = await openDatabase();
    const transaction = db.transaction('viajes', 'readwrite');
    const objectStore = transaction.objectStore('viajes');
    objectStore.put(trip);
};

const fetchTripFromIndexedDB = async (id) => {
    const db = await openDatabase();
    return new Promise((resolve) => {
        const transaction = db.transaction('viajes', 'readonly');
        const objectStore = transaction.objectStore('viajes');
        const request = objectStore.get(id);
        request.onsuccess = () => resolve(request.result);
    });
};

const fetchTripFromAPI = async (id, token) => {
    try {
        const response = await fetch(`https://dreamfly.es/api/get-trip/${id}`, {
            headers: { 'x-access-token': token }
        });
        const data = await response.json();
        if (response.ok) {
            await saveTripToIndexedDB(data.trip); // Guardar en IndexedDB
            return data.trip;
        } else if (data.message === 'Token expired') {
            return 'Token expired';
        } else {
            console.log('Algo ocurre (fetch trip from API)');
            return null;
        }
    } catch (err) {
        console.error('Error:', err);
        return null;
    }
};

function Trips(props) {
    const [trips, setTrips] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sessionExpired, setSessionExpired] = useState(false);

    useEffect(() => {
        (async () => {
            const cachedIds = getFromCache();
            if (cachedIds) {
                const tripsData = await Promise.all(cachedIds.map(async (id) => {
                    let trip = await fetchTripFromIndexedDB(id);
                    if (!trip) {
                        trip = await fetchTripFromAPI(id, props.token);
                        if (trip === 'Token expired') {
                            setSessionExpired(true);
                            return null;
                        }
                    }
                    return trip;
                }));
                handleTrips(tripsData.filter(trip => trip !== null));
                setLoading(false);
            } else {
                try {
                    const response = await fetch('https://dreamfly.es/api/my-trips', {
                        headers: { 'x-access-token': props.token }
                    });
                    const data = await response.json();
                    if (response.ok) {
                        const tripIds = data.trips; // Aquí data.trips ya contiene solo los IDs
                        if(tripIds.length > 0) {
                        saveToCache(tripIds);
                        const tripsData = await Promise.all(tripIds.map(async (id) => {
                            let trip = await fetchTripFromIndexedDB(id);
                            if (!trip) {
                                trip = await fetchTripFromAPI(id, props.token);
                                if (trip === 'Token expired') {
                                    setSessionExpired(true);
                                    return null;
                                }
                            }
                            return trip;
                        }));
                        
                        handleTrips(tripsData.filter(trip => trip !== null));
                        setLoading(false);
                    } else {
                        handleTrips([]);
                        setLoading(false);
                    }
                    } else if (data.message === 'Token expired') {
                        setSessionExpired(true);
                    } else {
                        console.log('Algo ocurre (trips else)');
                    }
                } catch (err) {
                    console.log('Error:', err);
                }
            }
        })();
    }, [props]);

    const handleTrips = (data) => {
        setTrips(data);
    };

    return (
        <>
            {sessionExpired && <SessionExpiredOverlay logout={props.logout} />}
            {loading ? (<LoadingAnimation />) : (
                <div className='mytrips-container' aria-label='Página de mis viajes'>
                    <div className="mytrips-overlay">
                        <div className="mytrips-encabezado" aria-label='Título de mis viajes'>
                            <h1>Mis aventuras</h1>
                            <h2>Tu próxima aventura te espera. ¿Estás listo para descubrirla?</h2>
                        </div>
                        <div className="mytrips-cards-container">
                            <ul className="mytrips-list" aria-label='Listado de mis viajes'>
                                {trips.map((trip, index) => (
                                    <li key={index} className="mytrips-list-item" aria-label={`Viaje-${index}`}>
                                        <Trip trip={trip} swipeMode={false} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {trips.length === 0 &&
                            <>
                                <div className="alternativa-noHayViajes" aria-label='No tienes viajes'>
                                    <h2>Aún no tienes viajes</h2>
                                </div>
                                <div className="alternativa-crearViaje" aria-label='Botón de crear un nuevo viaje'>
                                    <button
                                        type="button"
                                        onClick={() => { window.location.href = '/crear-viaje' }}
                                        aria-label='Crear un nuevo viaje'
                                    >
                                        Crea un nuevo viaje
                                    </button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            )}
        </>
    );
}

export default Trips;
