import React from 'react'
import OverlayAlerta from '../OverlayAlerta/overlay-alerta'

function EmailNotSentFP() {
  return (
    <div aria-label='Error al enviar el correo'>
        <OverlayAlerta
            titulo="Error al enviar el correo"
            subtitulo="Ha ocurrido un error al enviar el correo electrónico. Por favor, intenta nuevamente."
            btnMensaje="Cambiar contraseña"
            redirect="/forgotpassword" />
    </div>
  )
}

export default EmailNotSentFP