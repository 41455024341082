import React from 'react';
import './services.css';

const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Servicios de creación de viajes con IA totalmente gratis</h2>
          <p>
            ¡Podemos ofrecerte el viaje que siempre imaginaste, totalmente gratis!{<br/>}
            Creamos itinerarios personalizados en minutos, gracias a la inteligencia artificial y nuestra amplia base de datos.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <i className={d.icon} aria-hidden="true"></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};

export default Services;
