import React, { useState, useEffect, useRef } from 'react';
import './specific-trip.css';
import { useNavigate } from 'react-router-dom';
import AirplaneScroll from '../Airplane-scroll/airplane-scroll';
import MapComponent from '../Map/map';
import ActivityDetails from '../Activity-details-popup/activity-details';
import OverlayHotel from '../OverlayHotel/overlay-hotel';
import OverlayTransporte from '../OverlayTransporte/overlay-transporte';
import OverlayAlquiler from '../OverlayAlquilerCoche/overlay-alquiler';

function SpecificTrip({ trip }) {
    const navigate = useNavigate();

    const [showMap, setShowMap] = useState(window.innerWidth > 1000);
    const [mapLoaded, setMapLoaded] = useState(window.innerWidth > 1000);
    const [activeMarker, setActiveMarker] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [showWarning, setShowWarning] = useState(true);

    const [buyHotel, setBuyHotel] = useState(false);
    const [buyTransport, setBuyTransport] = useState(false);
    const [buyAlquiler, setBuyAlquiler] = useState(false);

    const [iconoMostrar, setIconoMostrar] = useState('');

    const bounceBtnRef = useRef(null);

    const goBack = () => {
        try {
            navigate(-1);
        } catch (error) {
            console.error("Navigation error:", error);
        }
    };

    const handleShowWarning = () => {
        setShowWarning(!showWarning);
    };

    const handleShowMapClick = () => {
        setShowMap(!showMap);
        setMapLoaded(true);
    };

    const handleActivityMouseEnter = (activity) => {
        if (activity.directions.lat && activity.directions.lon) {
            setActiveMarker({ name: activity.name, position: [activity.directions.lat, activity.directions.lon] });
            setSelectedActivity(activity);
        }
    };

    const handleAccommodationMouseEnter = (accommodation) => {
        if (accommodation.lat && accommodation.lon) {
            setActiveMarker({ name: accommodation.name, position: [accommodation.lat, accommodation.lon] });
        }
    };

    const handleActivityClick = (activity) => {
        handleActivityMouseEnter(activity);
        setSelectedActivity(activity);
        if (bounceBtnRef.current) {
            bounceBtnRef.current.classList.remove('btn-bounce');
            void bounceBtnRef.current.offsetWidth; // Reflow para reiniciar la animación
            bounceBtnRef.current.classList.add('btn-bounce');
        }
    };

    const handleCloseActivity = () => {
        setSelectedActivity(null);
    };

    const handleBuyTransport = () => {
        setBuyTransport(!buyTransport);
    };

    const handleBuyHotel = () => {
        setBuyHotel(!buyHotel);
    };

    const handleBuyAlquiler = () => {
        setBuyAlquiler(!buyAlquiler);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1000) {
                setShowMap(true);
                setMapLoaded(true);
            } else {
                setShowMap(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [showMap]);

    useEffect(() => {
        let transportTrip = trip.trip.transport.type;
        if (transportTrip === 'Avión' || transportTrip === 'Avion') {
            setIconoMostrar('fas fa-plane');
        } else if (transportTrip === 'Tren') {
            setIconoMostrar('fas fa-train');
        } else {
            setIconoMostrar('fa fa-car');
        }
    }, [trip]);

    return (
        <div className="container-specific-trip" aria-label='Página de un itinerario'>
            {buyHotel && <OverlayHotel handleBuyHotel={handleBuyHotel} />}
            {buyTransport && <OverlayTransporte handleBuyTransport={handleBuyTransport} transport={trip.trip.transport.type} />}
            {buyAlquiler && <OverlayAlquiler handleBuyAlquiler={handleBuyAlquiler} />}
            <div className="container-left-specific-trip">
                <div className="image-container" aria-label='Encabezado de la página'>
                    <button className="back-button" onClick={goBack} aria-label='Botón volver atrás'><span className='fa fa-arrow-left'></span><p> Atrás</p></button>
                    <img src={trip.URL_image} alt="Imagen del destino del itinerario generado por Dream Fly" className="trip-image" />
                    <h2 className='titulo-pagina'>{trip.trip.title}</h2>
                </div>
                <div className="transport-accommodation" aria-label='Sección de transporte'>
                    <div className="izquierda-transport">
                        <h2>Transporte</h2>
                        <div className="accommodation-avatares">
                            <div className="derecha-accommodation-avatar" aria-label='Tipo de transporte'>
                                <div className="avatar-initials"><span className={iconoMostrar}></span></div>
                                <div className="avatar-username">{trip.trip.transport.type}</div>
                            </div>
                            <div className="derecha-accommodation-avatar avatar-con-enlace" aria-label='Enlace para reservar transporte'>
                                <div className="avatar-initials"><span className='fas fa-calendar-check'></span></div>
                                <div className="avatar-username avatar-username-clicable"><a onClick={handleBuyTransport}>Reservar {trip.trip.transport.type.toLowerCase()}</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="salto-linea-responsive">
                        <hr className='salto-linea' />
                    </div>
                    <div className="izquierda-transport" aria-label='Sección del alojamiento'>
                        <h2>Alojamiento</h2>
                        <div className="accommodation-avatares">
                            <div className="derecha-accommodation-avatar avatar-con-enlace" aria-label='Alojamiento recomendado'>
                                <div className="avatar-initials"><span className='fa fa-home'></span></div>
                                <div className="avatar-username avatar-username-clicable"><a href={trip.trip.accommodation.google_maps} aria-label='Enlace a Google Maps del alojamiento recomendado'>{trip.trip.accommodation.name}</a></div>
                            </div>
                            <div className="derecha-accommodation-avatar avatar-con-enlace" aria-label='Reservar alojamiento'>
                                <div className="avatar-initials"><span className='fas fa-calendar-check'></span></div>
                                <div className="avatar-username avatar-username-clicable"><a onClick={handleBuyHotel} aria-label='Enlace para reservar alojamiento'>Reservar hotel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="salto-linea-responsive">
                        <hr className='salto-linea' />
                    </div>
                    <div className="derecha-accommodation" aria-label='Sección de alquiler de coche'>
                        <h2>Alquiler coche</h2>
                        <div className="accommodation-avatares">
                            <div className="derecha-accommodation-avatar avatar-con-enlace">
                                <div className="avatar-initials"><span className='fas fa-car'></span></div>
                                <div className="avatar-username avatar-username-clicable"><a onClick={handleBuyAlquiler} aria-label='Enlace para alquilar un coche'>Alquilar un coche</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <hr className='salto-linea' />
                <br />
                <div className="container-origen-destino" aria-label='Sección de origen y destino'>
                    <div className="origen-nombre" aria-label='Origen'>
                        <h2>Origen</h2>
                        <p>{trip.trip.origin}</p>
                    </div>
                    <AirplaneScroll />
                    <div className="destino-nombre" aria-label='Destino'>
                        <h2>Destino</h2>
                        <p>{trip.trip.destination}</p>
                    </div>
                </div>

                <div className="trip-details" aria-label='Sección de detalles del itinerario'>
                    {trip.trip.itinerary.map((day, index) => (
                        <div key={index} className="day-container" aria-label={`Día-${index}`}>
                            <h2 className='titulo-dia'>Día {day.day}</h2>
                            <p className='subtitulo-dia'>{day.title}</p>
                            <hr className='salto-linea salto-linea-activity' />
                            {day.activities.map((activity, idx) => (
                                <div key={idx} className="container-activity" aria-label={`Actividad-${idx}`}>
                                    {idx === 0 && index !== 0 ? (<div>
                                        <div className="activity" onMouseEnter={() => handleAccommodationMouseEnter(trip.trip.accommodation)} onClick={() => handleAccommodationMouseEnter(trip.trip.accommodation)} aria-label='Alojamiento recomendado en el destino'>
                                            <h3>{trip.trip.accommodation.name}</h3>
                                        </div>
                                    </div>) : <></>}
                                    <div className="activity-directions" aria-label='Sección de dirección'>
                                        <p><span className={activity.directions.mode === 'transit' ? 'fa fa-bus direction-mode' : 'fas fa-walking direction-mode'} ></span>{activity.directions.duration} mins</p>
                                        <span className='separacion-directions-enlace'>~</span>
                                        <a href={activity.directions.link} target="_blank" rel="noopener noreferrer" aria-label='Abrir Google Maps con la ruta a la actividad'>Abrir ruta (Google Maps)</a>
                                    </div>
                                    <div className="activity" aria-label='Detalles de la actividad'>
                                        <div className="activity-titulo-hover" onMouseEnter={() => handleActivityMouseEnter(activity)} onClick={() => handleActivityClick(activity)} aria-label='Nombre de la actividad'>
                                            <h3>{activity.name}</h3>
                                        </div>
                                        <p className='activity-description' aria-label='Descripción de la actividad'>{activity.description}</p>
                                        <div className='activity-time-price' aria-label='Hora y precio de la actividad'>
                                            <p className='activity-time'>{activity.time}</p>
                                            <span className='punto-separacion-time-price'>·</span>
                                            <p className='activity-price'>{activity.price}€</p>
                                        </div>
                                    </div>
                                    {idx === day.activities.length - 1 && index !== trip.trip.itinerary.length - 1 ? (
                                        <>
                                            <div className="activity-directions" aria-label='Abrir Google Maps con la ruta a la actividad'>
                                                <a href={`https://www.google.com/maps/dir/?api=1&origin='${activity.name}, ${trip.trip.destination}'&destination='${trip.trip.accommodation.name}, ${trip.trip.destination}'&travelmode=transit`} target="_blank" rel="noopener noreferrer">Abrir ruta (Google Maps)</a>
                                            </div>
                                            <div className="activity" aria-label='Alojamiento recomendado en el destino'>
                                                <h3>{trip.trip.accommodation.name}</h3>
                                            </div>
                                        </>
                                    ) : <></>}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            {mapLoaded && (
                <div className={`container-right-specific-trip ${showMap ? 'show' : 'hide'}`} aria-label='Mapa'>
                    <MapComponent 
                      accommodation={trip.trip.accommodation} 
                      days={trip.trip.itinerary} 
                      destination_lat={trip.trip.destination_lat} 
                      destination_lon={trip.trip.destination_lon} 
                      activeMarker={activeMarker} 
                      onMarkerClick={handleActivityClick} 
                    />
                    {showWarning && (
                        <div className="warning-map-error">
                            <div className='alert alert-warning container-alert' aria-label='Advertencia inexactitud en la ubicación de las actividades en el mapa'>
                                <span className='fa fa-exclamation-triangle icono-atencion'>{ }</span>
                                <span>La ubicación de los lugares pueden contener errores. Recomendamos siempre revisarlos.</span>
                                <span className='fa fa-times cerrar-alerta-error-ubicacion' onClick={handleShowWarning}></span>
                            </div>
                        </div>
                    )}
                    <div className="activity-popup-description">
                        <ActivityDetails activity={selectedActivity} handleCloseActivity={handleCloseActivity} destination={trip.trip.destination} />
                    </div>
                </div>
            )}
            {window.innerWidth <= 1000 && showMap && (
                <button className="close-map-button" onClick={handleShowMapClick} aria-label='Botón cerrar mapa'><span className='fa fa-times'></span></button>
            )}
            <div className="container-btn-mostrarMapa" aria-label='Botón mostrar mapa'>
                {window.innerWidth <= 1000 && (
                    <button ref={bounceBtnRef} className={!showMap ? 'btn-showMap' : 'btn-no-show-map'} onClick={handleShowMapClick}><span className='fa fa-map'></span></button>
                )}
            </div>
        </div>
    );
}

export default SpecificTrip;
