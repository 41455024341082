import React, { useState, useEffect } from 'react';
import OverlayAlerta from '../OverlayAlerta/overlay-alerta';

const AdBlockDetector = () => {
  const [isAdBlockDetected, setIsAdBlockDetected] = useState(false);

  useEffect(() => {
    // Crear el elemento del script para Google AdSense
    const googleAdSenseScript = document.createElement('script');
    googleAdSenseScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    googleAdSenseScript.async = true;
    googleAdSenseScript.onerror = () => setIsAdBlockDetected(true); // Si hay un error, AdBlock está activado

    document.body.appendChild(googleAdSenseScript);

    // Limpieza del script después de su uso
    return () => {
      document.body.removeChild(googleAdSenseScript);
    };
  }, []);

  return (
    <div aria-label='Adblock detectado'>
      {isAdBlockDetected && (
        <OverlayAlerta
          titulo="Desactive AdBlock en nuestro sitio web"
          subtitulo="Se ha detectado que usa AdBlock en nuestro sitio web. Ofrecemos un servicio gratuito que nos cuesta dinero y con los anuncios podemos costear los servicios, por lo que le pediríamos por favor que desactive AdBlock en nuestro sitio web. Muchas gracias."
          redirect="https://dreamfly.es/"
          btnMensaje="Volver a la página de inicio"
        />
      )}
    </div>
  );
};

export default AdBlockDetector;
