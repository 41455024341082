import React, { useEffect } from 'react';
import './overlay-anuncio.css';
import LoadingAnimation from '../Loading/loadinganimation';

const OverlayAnuncio = () => {

    useEffect(() => {
        // Añadir clase para bloquear el scroll
        document.body.classList.add('no-scroll');

        // Crear un elemento script
        const script = document.createElement('script');
        script.type = 'text/javascript';

        // Agregar el código que debe ejecutarse al script
        script.innerHTML = `
            aclib.runInterstitial({
                zoneId: '8685110',
            });
        `;

        // Agregar el script al head del documento
        document.head.appendChild(script);

        const script2 = document.createElement('script');
        script2.type = 'text/javascript';

        script2.innerHTML = `
        aclib.runInPagePush({
        zoneId: '8685238',
        refreshRate: 30,
        maxAds: 2,
    });
        `;

        document.head.appendChild(script2);
        
        // Remover el script del head del documento
        return () => {
            document.head.removeChild(script);
            document.head.removeChild(script2);
        };

    }, []);

    return (
        <div className="overlay-anuncio" aria-label='Creando itinerario del viaje'>
            <div className="overlay-anuncio-content">
                <h1>Creando itinerario</h1>
                <p>Los anuncios son para poder costear la página web ya que los servicios no son gratuitos. Muchas gracias</p>
                <LoadingAnimation />
            </div>
        </div>
    );
};

export default OverlayAnuncio;
