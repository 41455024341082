import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigation } from '../../components/Navbar/navigation';
import Favtrips from '../../components/Fav-trips/fav-trips';
import Contact from '../../components/Contact/contact';

function Favorite(props) {
  return (
    <>
      <Helmet>
        <title>Mis viajes favoritos - Dream Fly</title>
        <meta name="description" content="Revisa y organiza tus viajes favoritos en Dream Fly. Guarda los mejores itinerarios para futuras aventuras." />
        <meta name="keywords" content="viajes favoritos, itinerarios guardados, planificación de viajes, viajes personalizados, crear viajes con IA, Dream Fly" />
      </Helmet>
      <div>
        <Navigation token={props.token} logout={props.logout} />
        <Favtrips token={props.token} logout={props.logout}/>
        <Contact data={props.landingPageData}/>
      </div>
    </>
  )
}

export default Favorite;
