import React from 'react'
import './reset_not_ok.css';
import Logo from './Titulo.png';
import CruzRoja from './cruz_roja.png';

function ResetNotOk() {

    // Función para validar el email
    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleRedirectHome = () => {
        window.location.href = '/';
    }

    const handleCambiarContrasena = () => {
        window.location.href = '/forgotpassword';
    }
    
    return (
        <div className='reset_not_ok-container'>
            <div className='logo-reset_not_ok'>
                <img src={Logo} alt='Logo Dream Fly' onClick={handleRedirectHome}/>
            </div>
            <div className='reset_not_ok-body'>
                <div className="reset_not_ok-header">
                    <img src={CruzRoja} alt='Cruz Roja'></img>
                    <h1>Error cambiando la contraseña</h1>
                    <p>Para reenviar el correo para el cambio de contraseña pulse el botón de Cambiar Contraseña.</p>
                    <a
                        onClick={handleCambiarContrasena}
                        className="btn btn-custom btn-lg page-scroll"
                    >
                        CAMBIAR CONTRASEÑA
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ResetNotOk