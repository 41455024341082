import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function TokenHandler(props) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      props.setToken(token);
      navigate('/');
    }
  }, [location, props, navigate]);

  return (
    <div>
      {/* TODO: Create a spin */}
    </div>
  );
}

export default TokenHandler;
