import React from 'react';
import { Helmet } from 'react-helmet-async';
import LoginComponente from '../../components/Login/login';

function Login(props) {
  return (
    <>
      <Helmet>
        <title>Iniciar Sesión - Dream Fly</title>
        <meta name="description" content="Inicia sesión en Dream Fly para acceder a tus viajes y comenzar a planificar nuevas aventuras." />
        <meta name="keywords" content="iniciar sesión, login, acceso a cuenta, viajes personalizados, Dream Fly, crear viajes con IA" />
      </Helmet>
      <div>
        <LoginComponente setToken={props.setToken} redirect={props.redirect}/>
      </div>
    </>
  )
}

export default Login;
