import React, { useEffect, useState } from 'react';
import Specific from '../../components/Specific-trip/specific-trip';
import LoadingAnimation from '../../components/Loading/loadinganimation';
import SessionExpiredOverlay from '../../components/Session-expired/session-expired-overlay';
import { useParams } from 'react-router-dom';

const openDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('miBaseDeDatos', 1);
        request.onerror = (event) => reject(event);
        request.onsuccess = (event) => resolve(event.target.result);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('viajes')) {
                db.createObjectStore('viajes', { keyPath: '_id' });
            }
        };
    });
};

const fetchTripFromIndexedDB = async (id) => {
    const db = await openDatabase();
    return new Promise((resolve) => {
        const transaction = db.transaction('viajes', 'readonly');
        const objectStore = transaction.objectStore('viajes');
        const request = objectStore.get(id);
        request.onsuccess = () => resolve(request.result);
    });
};

function SpecificTrip(props) {
    const { id } = useParams();
    const [trip, setTrip] = useState();
    const [loading, setLoading] = useState(true);
    const [sessionExpired, setSessionExpired] = useState(false);

    useEffect(() => {
        (async () => {
            let tripData = await fetchTripFromIndexedDB(id);
            if (tripData) {
                setTrip(tripData);
                setLoading(false);
            } else {
                try {
                    const response = await fetch(`https://dreamfly.es/api/get-trip/${id}`, {
                        headers: { 'x-access-token': props.token }
                    });
                    const data = await response.json();
                    if (response.ok) {
                        setTrip(data.trip);
                        setLoading(false);
                    } else if (data.message === 'Token expired') {
                        setSessionExpired(true);
                    } else {
                        console.log('Algo ocurre (specific trip else)');
                    }
                } catch (err) {
                    console.error('Error:', err);
                }
            }
        })();
    }, [id]);

    return (
        <div>
            {sessionExpired && <SessionExpiredOverlay logout={props.logout} />}
            {loading ? (<LoadingAnimation />) : (
                <Specific trip={trip} />
            )}
        </div>
    );
}

export default SpecificTrip;
