import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigation } from '../../components/Navbar/navigation';
import Contact from '../../components/Contact/contact';
import ProfileComponent from '../../components/Profile/profile';

function Profile(props) {
  return (
    <>
      <Helmet>
        <title>Mi perfil - Dream Fly</title>
        <meta name="description" content="Gestiona tu perfil en Dream Fly. Actualiza tu información personal y gestiona tu cuenta." />
        <meta name="keywords" content="perfil de usuario, configuración de cuenta, viajes personalizados, Dream Fly" />
      </Helmet>
      <div>
        <Navigation token={props.token}/>
        <ProfileComponent token={props.token} logout={props.logout}/>
        <Contact data={props.landingPageData}/>
      </div>
    </>
  )
}

export default Profile;
