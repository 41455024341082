import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigation } from '../../components/Navbar/navigation';
import Create from '../../components/Create-Travel/create';
import Contact from '../../components/Contact/contact';

function CreateTravel(props) {
  return (
    <>
      <Helmet>
        <title>Crear un Viaje Personalizado - Dream Fly</title>
        <meta name="description" content="Crea un viaje personalizado con IA para tu próximo destino de forma rápida y sencilla con Dream Fly. Planifica cada detalle de tu aventura totalmente gratis." />
        <meta name="keywords" content="crear viaje, itinerarios personalizados, planificación de viajes, viajes a medida, crear viajes con IA, Dream Fly, Dream Fly Crear viajes" />
      </Helmet>
      <div>
        <Navigation token={props.token} logout={props.logout}/>
        <Create token={props.token} logout={props.logout}/>
        <Contact data={props.landingPageData}/>
      </div>
    </>
  )
}

export default CreateTravel;
