import React, { useState, useEffect } from "react";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import Login from "./screens/login/login";
import Register from "./screens/register/register";
import Home from "./screens/home/home";
import TokenHandler from "./components/TokenHandler/tokenHandler";
import CreateTravel from "./screens/create-travel/create-travel";
import MyTrips from "./screens/my-trips/my-trips";
import Explorar from "./screens/explorar-destinos/explorar-destinos";
import Favorite from "./screens/favorite-trips/favorite-trips";
import SpecificTrip from "./screens/specific-trip/specific-trip";
import Profile from "./screens/my-profile/profile";
import ForgotPassword from "./components/Forgot-pwd/forgot-password";
import TerminosCondiciones from "./screens/terminos-condiciones/terminos-condiciones";
import PoliticaPrivacidad from "./screens/politica-privacidad/politica-privacidad";
import VerifiedApproved from "./screens/verify-account/verified_approved";
import VerifiedDenied from "./screens/verify-account/verified_denied";
import EmailSent from "./screens/verify-account/email_sent";
import EmailNotSent from "./screens/verify-account/email_not_sent";
import EmailSentFP from "./components/Forgot-pwd/email_sent_fp";
import EmailNotSentFP from "./components/Forgot-pwd/email_not_sent_fp";
import AccountNotVerified from "./screens/verify-account/account_not_verified";
import ResetPassword from "./components/Reset-pwd/reset-password";
import ResetOk from "./screens/Reset-pwd/reset_ok";
import ResetNotOk from "./screens/Reset-pwd/reset_not_ok";
import AdBlockDetector from "./components/AdBlock/ad_block";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {

  const [token, setToken] = useState(localStorage.getItem('token'));
  const [landingPageData, setLandingPageData] = useState({});


  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const handleToken = (token) => {
    setToken(token);
    localStorage.setItem('token', token);
  }

  const logout = () => {
    window.location.href = '/login';
    setToken(null);
    localStorage.removeItem('token');

    // Clear cache
    sessionStorage.removeItem('top-liked-home');
    sessionStorage.removeItem('travel-swipe');
    sessionStorage.removeItem('mis-viajes-ids');
    sessionStorage.removeItem('fav-trips-ids');
    sessionStorage.removeItem('dislike-trips-ids');
  };

  return (
    <>
      <AdBlockDetector />
      <Router>
        <Routes>
          <Route path="/" element={<Home landingPageData={landingPageData} token={token} logout={logout} />} />
          <Route path="/login" element={token ? <Navigate to="/" /> : <Login setToken={handleToken} redirect={"/"} />} />
          <Route path="/register" element={token ? <Navigate to="/" /> : <Register setToken={handleToken} redirect={"/"} />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/token-handler" element={<TokenHandler setToken={handleToken} />} />
          <Route path="/crear-viaje" element={<CreateTravel landingPageData={landingPageData.Contact} token={token} logout={logout} />} />
          <Route path="/mis-viajes" element={token ? <MyTrips landingPageData={landingPageData.Contact} token={token} logout={logout} /> : <Login setToken={handleToken} redirect={"/mis-viajes"} />} />
          <Route path="/explorar-destinos" element={<Explorar landingPageData={landingPageData.Contact} token={token} logout={logout} />} />
          <Route path="/mis-favoritos" element={token ? <Favorite landingPageData={landingPageData.Contact} token={token} logout={logout} /> : <Login setToken={handleToken} redirect={"/mis-favoritos"} />} />
          <Route path="/mi-perfil" element={token ? <Profile landingPageData={landingPageData.Contact} token={token} logout={logout} /> : <Login setToken={handleToken} redirect={"/mi-perfil"} />} />
          <Route path="/viaje/:id" element={token ? <SpecificTrip token={token} logout={logout} /> : <Login setToken={handleToken} redirect={"/viaje/:id"} />} />
          <Route path="/terminos-condiciones" element={<TerminosCondiciones />} />
          <Route path="/politica-privacidad" element={<PoliticaPrivacidad />} />
          <Route path="/verified-approved" element={<VerifiedApproved />} />
          <Route path="/verified-denied" element={<VerifiedDenied />} />
          <Route path="/email-sent" element={<EmailSent />} />
          <Route path="/email-not-sent" element={<EmailNotSent />} />
          <Route path="/email-sent-fp" element={<EmailSentFP />} />
          <Route path="/email-not-sent-fp" element={<EmailNotSentFP />} />
          <Route path="/account-not-verified" element={<AccountNotVerified />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-ok" element={<ResetOk />} />
          <Route path="/reset-not-ok" element={<ResetNotOk />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
