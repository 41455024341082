import React, { useState } from 'react';
import './reset-password.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Logo from '../Login/Logo-chico.png';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ResetPassword = () => {
    const query = useQuery();
    const token = query.get('token');

    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    //// PASSWORD VISIBILITY ////
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);

    const [inputError, setInputError] = useState(false);
    const [passwordDoesNotMatch, setPasswordDoesNotMatch] = useState(false);
    const [serverError, setServerError] = useState(false);

    const [thinking, setThinking] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(!passwordVisible2);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setThinking(true);
        if (password === '' || passwordConfirmation === '') {
            setInputError(true);
            setThinking(false);
            setTimeout(() => {
                setInputError(false);
            }, 8000);
        } else if (password !== passwordConfirmation) {
            setPasswordDoesNotMatch(true);
            setThinking(false);
            setTimeout(() => {
                setPasswordDoesNotMatch(false);
            }, 8000);
        } else {
            try {
                const response = await fetch('https://dreamfly.es/api/reset-password', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ token, password })
                });
                const data = await response.json();
                if (response.ok) {
                    if (data.message === 'Reset confirmed') {
                        window.location.href = '/reset-ok';
                    }
                } else {
                    window.location.href = '/reset-not-ok';
                }
            } catch (error) {
                setThinking(false);
                setServerError(true);
            }
        }
    };

    const handleInicio = () => {
        window.location.href = '/';
    };

    return (
        <div className="reset-pwd-container-container">
            <div className="logo-reset-pwd">
                <img src={Logo} alt="Logo Dream Fly - Personalizar viaje gratis" onClick={handleInicio} />
            </div>
            <div className="forgot-password-container">
                <form onSubmit={handleSubmit} aria-label='Formulario cambiar contraseña'>
                    <h2 className='titulo-reset-pwd'>Cambiar contraseña</h2>
                    <p className='texto-reset-pwd'>Introduzca la nueva contraseña para tu cuenta. ¡Tus viajes estarán a salvo!</p>
                    <div className="form-group-register form-group-register-password reset-pwd-input" aria-label='Introduce la nueva contraseña'>
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            id="password"
                            placeholder=" "
                            value={password}
                            autoComplete='new-password'
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <label htmlFor="password" className="floating-label">Contraseña <span style={{ color: 'red' }}> *</span></label>
                        <span
                            className="toggle-password"
                            onClick={togglePasswordVisibility}
                        >
                            {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                        </span>
                    </div>
                    <div className="form-group-register form-group-register-passwordConfirmation reset-pwd-input" aria-label='Introduce de nuevo la nueva contraseña'>
                        <input
                            type={passwordVisible2 ? 'text' : 'password'}
                            id="passwordConfirmation"
                            placeholder=" "
                            value={passwordConfirmation}
                            autoComplete='new-password'
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                        />
                        <label htmlFor="password" className="floating-label">Repita la contraseña <span style={{ color: 'red' }}> *</span></label>
                        <span
                            className="toggle-password"
                            onClick={togglePasswordVisibility2}
                        >
                            {passwordVisible2 ? <FaEye /> : <FaEyeSlash />}
                        </span>
                    </div>
                    {inputError && <div className='alert alert-danger alert-rp' aria-label='Botón registrarse'>Por favor, rellene todos los campos.</div>}
                    {passwordDoesNotMatch && <div className='alert alert-danger alert-rp' aria-label='Botón registrarse'>Las contraseñas no coinciden.</div>}
                    {serverError && <div className='alert alert-danger alert-rp' aria-label='Botón registrarse'>Error del servidor. Por favor, inténtelo de nuevo.</div>}
                    <button type="submit" className='btn-enviar-reset-pwd' aria-label='Botón enviar'>
                        {thinking ? (
                            <div className="puntos-animados-loading">
                            <div className="puntos"></div>
                            <div className="puntos" style={{animationDelay: '0,2s'}}></div>
                            <div className="puntos" style={{animationDelay: '0.4s'}}></div>
                        </div>
                        ): (
                            <span>Enviar</span>
                        )}
                    </button>
                </form>
                
            </div>
        </div>
    );
};

export default ResetPassword;

