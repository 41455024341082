import React from 'react'
import OverlayAlerta from '../../components/OverlayAlerta/overlay-alerta'

function EmailSent() {
  return (
    <div>
        <OverlayAlerta
            titulo="¡Correo enviado!"
            subtitulo="Se ha enviado un correo de verificación a tu dirección de correo electrónico. Por favor, revisa tu bandeja de entrada y sigue las instrucciones para verificar tu cuenta. Si no lo recibes, comprueba la carpeta de spam o solicita un nuevo correo de verificación."
            btnMensaje="Iniciar sesión"
            redirect="/login" />
    </div>
  )
}

export default EmailSent