import React, { useState } from 'react';
import './forgot-password.css';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [serverError, setServerError] = useState(false);

    const [thinking, setThinking] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setThinking(true);
        try {
            const response = await fetch('https://dreamfly.es/api/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });
            const data = await response.json();
            if (response.ok) {
                if (data.message === 'Email sent') {
                    window.location.href = '/email-sent-fp';
                }
            } else {
                window.location.href = '/email-not-sent-fp';
            }
        } catch (error) {
            setThinking(false);
            setServerError(true);
        }
    };

    const goBack = () => {
        try {
            navigate(-1);
        } catch (error) {
            console.error("Navigation error:", error);
        }
    };

    return (
        <div className="forgot-pwd-container-container" aria-label='Página he olvidado la contraseña'>
            <div className="logo-forgot-pwd">
                <button className="back-button-forgot-pwd" onClick={goBack} aria-label='Botón volver atrás'><span className='fa fa-arrow-left'></span><p> Atrás</p></button>
            </div>
            <div className="forgot-password-container">
                <form onSubmit={handleSubmit} aria-label='Formulario olvidado contraseña'>
                    <h2 className='titulo-forgot-pwd'>¿Olvidaste tu contraseña?</h2>
                    <p className='texto-forgot-pwd'>Cuando planeas un viaje, es fácil olvidar algo importante. ¡Recupera tu contraseña y sigue explorando el mundo!</p>
                    <div className="form-group form-group-forgot-pwd" aria-label='Introduce su email'>
                        <input type="email" id="email" value={email} placeholder=" " autoComplete='username' onChange={(e) => setEmail(e.target.value)} />
                        <label htmlFor="email" className="floating-label">Email</label>
                    </div>
                    <button type="submit" className='btn-enviar-forgot-pwd' aria-label='Botón enviar'>
                        {thinking ? (
                            <div className="puntos-animados-loading">
                                <div className="puntos"></div>
                                <div className="puntos" style={{ animationDelay: '0,2s' }}></div>
                                <div className="puntos" style={{ animationDelay: '0.4s' }}></div>
                            </div>
                        ) : (
                            <span>Enviar</span>
                        )}
                    </button>
                </form>
                {serverError && <div className='alert alert-danger alert-rp' aria-live='assertive'>Error del servidor. Por favor, inténtelo de nuevo.</div>}
            </div>
        </div>
    );
};

export default ForgotPassword;
