import React, { useEffect, useState } from 'react';
import './overlay-transporte.css';
import LoadingAnimation from '../Loading/loadinganimation';

const OverlayTransporte = (props) => {

    const [isAvion, setIsAvion] = useState(false);
    const [isTren, setIsTren] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Añadir clase para bloquear el scroll
        document.body.classList.add('no-scroll');

        return () => {
            // Remover clase al desmontar el componente
            document.body.classList.remove('no-scroll');
        };
    }, []);

    useEffect(() => {
        if (props.transport === 'Avión' || props.transport === 'Avion') {
            setIsAvion(true);
            setIsTren(false);
            setIsLoading(false);
        } else if (props.transport === 'Tren') {
            setIsTren(true);
            setIsAvion(false);
            setIsLoading(false);
        } else {
            console.log("Algo fue mal")
        }
    });

    const handleClick = () => {
        props.handleBuyTransport();
    };

    return (
        <div className="overlay-transport" aria-label='Reservar transporte'>
            {isLoading && <LoadingAnimation />}
            <div className="overlay-transport-close" onClick={handleClick} aria-label='Cerrar reserva de transporte'>
                <i className="fas fa-times">   Cerrar reserva</i>
            </div>
            <div className="overlay-transport-content" aria-label='Widget para la reserva del transporte'>
                {isTren && <iframe border="0" src="https://www.trip.com/partners/ad/S517617?Allianceid=5116182&SID=101655884&trip_sub1=" id="S517617"></iframe>}
                {isAvion && <iframe border="0" src="https://es.trip.com/partners/ad/S517379?Allianceid=5116182&SID=101655884&trip_sub1=" id="S517379"></iframe>}
            </div>
        </div>
    );
};

export default OverlayTransporte;
