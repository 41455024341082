import React, { useEffect, useState } from 'react';
import './profile-details.css';
import LoadingAnimation from '../Loading/loadinganimation';
import SessionExpiredOverlay from '../Session-expired/session-expired-overlay';

const openDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('miBaseDeDatos', 1);
        request.onerror = (event) => reject(event);
        request.onsuccess = (event) => resolve(event.target.result);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('viajes')) {
                db.createObjectStore('viajes', { keyPath: '_id' });
            }
        };
    });
};

const deleteTripFromIndexedDB = async (id) => {
    const db = await openDatabase();
    const transaction = db.transaction('viajes', 'readwrite');
    const objectStore = transaction.objectStore('viajes');
    objectStore.delete(id);
};

const updateSessionStorage = (tripIds) => {
    const cacheData = {
        timestamp: Date.now(),
        data: tripIds,
    };
    sessionStorage.setItem('dislike-trips-ids', JSON.stringify(cacheData));
};

const ProfileDetails = (props) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [trips, setTrips] = useState([]);
    const [loading, setLoading] = useState(true);
    const [removeDislike, setRemoveDislike] = useState(false);
    const [errorDislike, setErrorDislike] = useState(false);
    const [errorGuardarPerfil, setErrorGuardarPerfil] = useState(false);
    const [errorCampoObligatorio, setErrorCampoObligatorio] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [sessionExpired, setSessionExpired] = useState(false);

    useEffect(() => {
        if (props.username && props.email && props.trips) {
            setUsername(props.username);
            setEmail(props.email);
            setTrips(props.trips);
            setLoading(false);
        }
    }, [props.username, props.email, props.trips]);

    const handleVerViaje = (viaje_id) => () => {
        window.location.href = `/viaje/${viaje_id}`;
    };

    const handleQuitarDislike = (viaje_id) => async () => {
        try {
            console.log("Vamos a quitar el dislike");
            const response = await fetch('https://dreamfly.es/api/remove-dislike/' + viaje_id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': props.token
                }
            });
            const data = await response.json();
            if (response.ok && data.message === 'Dislike removed') {
                console.log("Dislike eliminado correctamente");
                // Eliminar de IndexedDB
                await deleteTripFromIndexedDB(viaje_id);

                // Actualizar la lista de trips y sessionStorage
                const updatedTrips = trips.filter(trip => trip._id !== viaje_id);
                setTrips(updatedTrips);
                const updatedTripIds = updatedTrips.map(trip => trip._id);
                updateSessionStorage(updatedTripIds);
                if(updatedTrips.length === 0) {
                    sessionStorage.removeItem('dislike-trips-ids');
                }
                setRemoveDislike(true);
                setTimeout(() => {
                    setRemoveDislike(false);
                }, 4000);
            } else if (data.message === 'Token expired') {
                setSessionExpired(true);
            } else {
                setErrorDislike(true);
                setTimeout(() => {
                    setErrorDislike(false);
                }, 4000);
            }
        } catch (error) {
            console.log('Error: ' + error);
        }
    };

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleGuardarPerfil = async (e) => {
        if (username === '' || email === '') {
            setErrorCampoObligatorio(true);
            setTimeout(() => {
                setErrorCampoObligatorio(false);
            }, 4000);
        } else if (!isValidEmail(email)) {
            setEmailError(true);
            setTimeout(() => {
                setEmailError(false);
            }, 4000);

        } else {
            e.preventDefault();
            try {
                const response = await fetch('https://dreamfly.es/api/edit-profile', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': props.token
                    },
                    body: JSON.stringify({ username, email })
                });
                const data = await response.json();
                if (response.ok && data.message === 'User updated') {
                    window.location.reload();
                } else if (data.message === 'Token expired') {
                    setSessionExpired(true);
                } else {
                    setErrorGuardarPerfil(true);
                    setTimeout(() => {
                        setErrorGuardarPerfil(false);
                    }, 4000);
                }
            }
            catch (error) {
                console.log('Error: ' + error);
            }
        }
    };

    return (
        <>
            {sessionExpired && <SessionExpiredOverlay logout={props.logout} />}
            {loading ? <LoadingAnimation /> :
                <>
                    <div className="profile-details" aria-label='Datos personales'>
                        <h2>Datos personales</h2>
                        <form aria-label='Formulario datos personales'>
                            <div className='alert alert-info alert-mandatory-input' style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }} aria-label='Advertencia sobre los campos obligatorios'>
                                <span style={{ flexGrow: 1, width: '100%' }}>Los campos con asterisco (<span style={{ color: 'red' }}>*</span>) son obligatorios.</span>
                            </div>
                            <div className="form-group profile-details-form" aria-label='Input nombre'>
                                <input
                                    type="text"
                                    id="username"
                                    placeholder=" "
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                <label htmlFor="username" className="floating-label">Nombre <span style={{ color: 'red' }}> *</span></label>
                            </div>
                            <div className="form-group profile-details-form" aria-label="Input Email">
                                <input
                                    type="email"
                                    id="email"
                                    placeholder=" "
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label htmlFor="email" className="floating-label">Email <span style={{ color: 'red' }}> *</span></label>
                            </div>
                            <div className="form-group form-group-forgot-password" aria-label='Enlace para cambiar la contraseña'>
                                <a href="/forgotpassword">Cambiar la contraseña</a>
                            </div>
                            {errorGuardarPerfil && <div className="alert alert-danger alert-mandatory-input" aria-live='assertive'>Ha ocurrido un error al guardar los cambios</div>}
                            {errorCampoObligatorio && <div className="alert alert-danger alert-mandatory-input" aria-live='assertive'>Los campos marcados con asterisco son obligatorios</div>}
                            {emailError && <div className="alert alert-danger alert-mandatory-input" aria-live='assertive'>El email no es válido</div>}
                            <div className="profile-details-guardar-button" aria-label='Botón guardar datos personales'>
                                <button type="button" onClick={handleGuardarPerfil}>Guardar</button>
                            </div>
                        </form>
                    </div>

                    <div className="profile-details" aria-label='Historial de dislikes'>
                        <h2>Historial dislikes</h2>
                        {removeDislike && <div className="alert alert-success alert-mandatory-input" aria-live='assertive'>Dislike eliminado correctamente</div>}
                        {errorDislike && <div className="alert alert-danger alert-mandatory-input" aria-live='assertive'>Ha ocurrido un error al eliminar el dislike</div>}
                        {trips.length === 0 ? <p aria-label='No tienes ningún dislike'>No tienes ningún dislike</p>
                            :
                            <table className="dislikes-table" aria-label='Tabla con el historial de dislike'>
                                <thead>
                                    <tr>
                                        <th aria-label='Titulo origen'>Origen</th>
                                        <th aria-label='Titulo destino'>Destino</th>
                                        <th aria-label='Titulo presupuesto'>Presupuesto</th>
                                        <th aria-label='Titulo botón ver viaje'>Ver viaje</th>
                                        <th aria-label='Titulo botón quitar dislikes'>Quitar dislike</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trips.length > 0 && trips.map((trip, index) => (
                                        <tr key={index} aria-label={`Fila-${index}`}>
                                            <td data-label="Origen">{trip.trip.origin}</td>
                                            <td data-label="Destino">{trip.trip.destination}</td>
                                            <td data-label="Presupuesto">{trip.total_expenses_per_person}€</td>
                                            <td data-label="Ver viaje">
                                                <button onClick={handleVerViaje(trip._id)} aria-label='Botón ver viaje'>Ver viaje</button>
                                            </td>
                                            <td data-label="Quitar dislike">
                                                <button onClick={handleQuitarDislike(trip._id)} aria-label='Botón quitar dislike'>Quitar dislike</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                    </div>
                </>
            }
        </>
    );
};

export default ProfileDetails;
