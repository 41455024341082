import React from 'react'
import './reset_ok.css';
import Logo from './Titulo.png';
import TickVerde from './tick_verde.png';

function ResetOk() {

    const handleIniciarSesion = () => {
        window.location.href = '/login';
    }

    const handleRedirectHome = () => {
        window.location.href = '/';
    }

    return (
        <div className='reset_ok-container'>
            <div className='logo-reset_ok'>
                <img src={Logo} alt='Logo Dream Fly' onClick={handleRedirectHome}/>
            </div>
            <div className='reset_ok-body'>
                <div className="reset_ok-header">
                    <img src={TickVerde} alt='Tick verde'></img>
                    <h1>¡Contraseña cambiada con éxito!</h1>
                    <p>¡Felicidades! Ya puedes retornar tus viajes sin ningún impedimento.</p>
                    <p>Inicia sesión y comienza a planificar esos viajes soñados que siempre has querido realizar.</p>
                    <a
                  href="/login"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  INICIAR SESIÓN
                </a>
                </div>
            </div>
        </div>
    )
}

export default ResetOk