import React, { useState } from 'react';
import OverlayAlerta from '../OverlayAlerta/overlay-alerta';
import './contact.css';

const Contact = (props) => {
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [mensaje, setMensaje] = useState('');

  const [inputError, setInputError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [emailNoEnviado, setEmailNoEnviado] = useState(false);
  const [emailEnviado, setEmailEnviado] = useState(false);

  const [thinking, setThinking] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setThinking(true);
    if (nombre === '' || email === '' || mensaje === '') {
      setInputError(true);
      setThinking(false);
      setTimeout(() => {
        setInputError(false);
        return;
      }, 8000);
    } else if (!isValidEmail(email)) {
      setEmailError(true);
      setThinking(false);
      setTimeout(() => {
        setEmailError(false);
        return;
      }, 8000);
    } else {
      try {
        const response = await fetch('https://dreamfly.es/api/mensaje-contacto', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ nombre, email, mensaje }),
        });
        const data = await response.json();
        if (response.ok) {
          if (data.message === 'Email sent') {
            setEmailEnviado(true);
            setThinking(false);
            setTimeout(() => {
              setEmailEnviado(false);
              setNombre('');
              setEmail('');
              setMensaje('');
              return;
            }, 8000);
          }
        } else {
          if (data.message === 'Email not sent') {
            setEmailNoEnviado(true);
            setThinking(false);
            setTimeout(() => {
              setEmailNoEnviado(false);
            }, 8000);
            return;
          } else {
            setServerError(true);
            setThinking(false);
            setTimeout(() => {
              setServerError(false);
            }, 8000);
            return;
          }
        }
      } catch (error) {
        setServerError(true);
        setThinking(false);
        setTimeout(() => {
          setServerError(false);
        }, 8000);
        console.log("Error: ", error);
        return;
      }
    };
  }

  const handleNombre = (e) => {
    setNombre(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleMensaje = (e) => {
    setMensaje(e.target.value);
  };

  const handleTerminosCondiciones = () => {
    window.open("https://dreamfly.es/terminos-condiciones", "_blank");
  };

  const handlePoliticaPrivacidad = () => {
    window.open("https://dreamfly.es/politica-privacidad", "_blank");
  };

  // Función para validar el email
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className='container-form'>
                <div className="section-title" aria-label='Sección contacto'>
                  <h2>Contáctanos</h2>
                  <p className='contact-subtitle'>
                    Por favor rellene el siguiente formulario para enviarnos un email
                    y le contactaremos lo antes posible, gracias.
                  </p>
                </div>
                <form name="sentMessage" onSubmit={handleSubmit} aria-label='Formulario de contacto de Dream Fly'>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group" aria-label='Nombre'>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={nombre}
                          className="form-control"
                          placeholder="Nombre"
                          required
                          onChange={handleNombre}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" aria-label='Email'>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          className="form-control"
                          placeholder="Email"
                          required
                          onChange={handleEmail}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group" aria-label='Mensaje'>
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      value={mensaje}
                      placeholder="Mensaje"
                      required
                      onChange={handleMensaje}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  {inputError && <div className='alert alert-danger alert-rp' aria-live="assertive">Por favor, rellene todos los campos.</div>}
                  {emailError && <div className='alert alert-danger alert-rp' aria-live="assertive">Por favor, introduzca un email válido.</div>}
                  {emailEnviado && <OverlayAlerta titulo='Email enviado correctamente.' subtitulo='Nos pondremos en contacto contigo lo más rápido posible. Gracias por confiar y ponerte en contacto con Dream Fly.' redirect='https://dreamfly.es/' btnMensaje='Volver al inicio' />}
                  {emailNoEnviado && <OverlayAlerta titulo='Error al enviar el email.' subtitulo='Ha ocurrido un error al enviar el email. Por favor, inténtelo más tarde. Gracias' redirect='https://dreamfly.es/' btnMensaje='Volver al inicio' />}
                  {serverError && <div className='alert alert-danger alert-rp' aria-live="assertive">Error del servidor. Por favor, inténtelo de nuevo.</div>}
                  <div id="success">
                    <button type="submit" className="btn btn-custom btn-lg" aria-label='Botón enviar mensajeF'>
                      <div>
                        {thinking ? (
                          <div className="puntos-animados-loading">
                            <div className="puntos"></div>
                            <div className="puntos" style={{ animationDelay: '0,2s' }}></div>
                            <div className="puntos" style={{ animationDelay: '0.4s' }}></div>
                          </div>
                        ) : (
                          <span>Enviar</span>
                        )}
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info" aria-label='Información de contacto'>
            <div className="contact-item">
              <h3>Información de contacto</h3>
            </div>

            <div className="contact-item" aria-label='Email'>
              <p>
                <span>
                  <i className="fas fa-envelope"></i> Email
                </span>
                {props.data ? props.data.email : "loading"}
              </p>
            </div>

            <br></br>

            <div className="contact-item" aria-label='Información legal - Dream Fly'>
              <h3>Información legal</h3>
              <p>
                <span>
                  <a onClick={handleTerminosCondiciones}>Términos y condiciones</a>
                </span>
              </p>
            </div>

            <div className="contact-item">
              <p>
                <span>
                  <a onClick={handlePoliticaPrivacidad}>Política de privacidad</a>
                </span>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
