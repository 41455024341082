import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import Trip from '../../components/Trip/trip';
import LoadingAnimation from '../../components/Loading/loadinganimation';
import SessionExpiredOverlay from '../../components/Session-expired/session-expired-overlay';
import './top-liked.css';

const CACHE_KEY = 'top-liked-home';
const CACHE_EXPIRATION_MS = 1440 * 60 * 1000; // 24 horas

const getFromCache = () => {
    const cachedData = sessionStorage.getItem(CACHE_KEY);
    if (!cachedData) return null;

    const { timestamp, data } = JSON.parse(cachedData);
    if (Date.now() - timestamp > CACHE_EXPIRATION_MS) {
        sessionStorage.removeItem(CACHE_KEY);
        return null;
    }

    return data;
};

const saveToCache = (data) => {
    const cacheData = {
        timestamp: Date.now(),
        data,
    };
    sessionStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
};



function TopLiked(props) {
    const [trips, setTrips] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sessionExpired, setSessionExpired] = useState(false);

    useEffect(() => {
        (async () => {
            const cachedData = getFromCache();
            if (cachedData) {
                setTrips(cachedData);
                setLoading(false);
                return;
            } else {
                try {
                    const response = await fetch('https://dreamfly.es/api/get-top-liked-trips');
                    const data = await response.json();
                    if (response.ok) {
                        setTrips(data.trips || []);
                        saveToCache(data.trips);
                        setLoading(false);
                    } else if (data.message === 'Token expired') {
                        setSessionExpired(true);
                    }
                } catch (err) {
                    console.log('Error:', err);
                    setLoading(false);
                }
            }
        })();
    }, [props.token]);

    const settings = {
        dots: true,
        infinite: false,
        autoplay: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    return (
        <>
            {sessionExpired && <SessionExpiredOverlay logout={props.logout} />}
            {loading ? (<LoadingAnimation />) : (
                <div className='top-liked-container'>
                    <div className="top-liked-overlay">
                        <div className="top-liked-encabezado">
                            <h1>Inspírate en otros viajeros</h1>
                            <hr className='salto-linea'></hr>
                            <h2>Estos son los viajes más gustados por los viajeros, o si lo prefieres, descubre nuevos sitios con nuestro <a href="/explorar-destinos">Travel Swipe</a>. ¡Una manera rápida y divertida de encontrar tus destinos favoritos!</h2>
                        </div>
                        <div className="slider-container">
                            <Slider {...settings}>
                                {trips.length > 0 ? trips.map((trip, index) => (
                                    <div key={index} className="top-liked-list-item">
                                        <Trip trip={trip.tripDetails} swipeMode={false} />
                                    </div>
                                )) : <p>No hay viajes disponibles.</p>}
                            </Slider>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TopLiked;
