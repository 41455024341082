import React, { useEffect, useState } from 'react';
import './airplane-scroll.css'; // Asegúrate de crear este archivo CSS

const AirplaneScroll = () => {
  const [airplaneStyle, setAirplaneStyle] = useState({});
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const maxScroll = document.body.scrollHeight - window.innerHeight;
    const scrollFraction = scrollY / maxScroll;

    const containerWidth = document.querySelector('.airplane-container').offsetWidth;
    const airplaneX = containerWidth * (scrollFraction * 1.5); // Aumenta el factor para mover más rápido en X
    const airplaneY = calculateAirplaneY(scrollFraction);

    const rotation = scrollY > lastScrollY ? -20 : -200; // Rotación según la dirección del desplazamiento

    setAirplaneStyle({
      transform: `translate(${airplaneX}px, ${airplaneY}px) rotate(${rotation}deg)`,
    });

    setLastScrollY(scrollY);
  };

  const calculateAirplaneY = (fraction) => {
    const amplitude = 60; // La amplitud de la subida y bajada
    const offset = -10; // Para ajustar la posición inicial

    return offset - (amplitude * Math.sin(fraction * Math.PI * 1.5)); // Aumenta el factor para mover más rápido en Y
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div className="airplane-container">
      <div className="clouds">
        <span className='fa fa-cloud'></span>
        <span className='fa fa-cloud'></span>
        <span className='fa fa-cloud'></span>
      </div>
      <div
        className="airplane"
        style={airplaneStyle}
      >
        <span className='fa fa-plane'></span>
      </div>
    </div>
  );
};

export default AirplaneScroll;
