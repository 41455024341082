// src/LoadingPage.js
import React from 'react';
import './loadinganimation.css';

const LoadingAnimation = () => {
    return (
        <div className="loading-container">
            <svg
                className="plane"
                viewBox="0 0 576 512"
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className="plane-body"
                    d="M482.3 192C516.5 192 576 221 576 256C576 292 516.5 320 482.3 320H365.7L265.2 495.9C259.5 505.8 248.9 512 237.4 512H181.2C170.6 512 162.9 501.8 165.8 491.6L214.9 320H112L68.8 377.6C65.78 381.6 61.04 384 56 384H14.03C6.284 384 0 377.7 0 369.1C0 368.7 .1818 367.4 .5398 366.1L32 256L.5398 145.9C.1818 144.6 0 143.3 0 142C0 134.3 6.284 128 14.03 128H56C61.04 128 65.78 130.4 68.8 134.4L112 192H214.9L165.8 20.4C162.9 10.17 170.6 0 181.2 0H237.4C248.9 0 259.5 6.153 265.2 16.12L365.7 192H482.3z"
                    fill="white"
                />
                <path
                    className="plane-edges"
                    d="M482.3 192C516.5 192 576 221 576 256C576 292 516.5 320 482.3 320H365.7L265.2 495.9C259.5 505.8 248.9 512 237.4 512H181.2C170.6 512 162.9 501.8 165.8 491.6L214.9 320H112L68.8 377.6C65.78 381.6 61.04 384 56 384H14.03C6.284 384 0 377.7 0 369.1C0 368.7 .1818 367.4 .5398 366.1L32 256L.5398 145.9C.1818 144.6 0 143.3 0 142C0 134.3 6.284 128 14.03 128H56C61.04 128 65.78 130.4 68.8 134.4L112 192H214.9L165.8 20.4C162.9 10.17 170.6 0 181.2 0H237.4C248.9 0 259.5 6.153 265.2 16.12L365.7 192H482.3z"
                    fill="none"
                    stroke="#5da2fc"
                    strokeWidth="15"
                />
            </svg>
            <div className="espacio-loading" aria-live='Pantalla cargando'>
                <h1 className="cargando-loading">Cargando</h1>
                <div className="puntos-animados-loading">
                    <div className="puntos"></div>
                    <div className="puntos" style={{animationDelay: '0,2s'}}></div>
                    <div className="puntos" style={{animationDelay: '0.4s'}}></div>
                </div>
            </div>
        </div>

    );
};

export default LoadingAnimation;
