import React from 'react';
import { Helmet } from 'react-helmet-async';
import RegisterComponent from '../../components/Register/register';
import OverlayAlerta from '../../components/OverlayAlerta/overlay-alerta';

function Register(props) {
  return (
    <>
      <Helmet>
        <title>Crear Cuenta - Dream Fly</title>
        <meta name="description" content="Crea una cuenta en Dream Fly y comienza a planificar viajes personalizados de forma gratuita. Es rápido y sencillo." />
        <meta name="keywords" content="crear cuenta, registro, nueva cuenta, viajes personalizados, crear viajes con IA" />
      </Helmet>
      <div>
        <RegisterComponent setToken={props.setToken} redirect={props.redirect}/>
      </div>
    </>
  )
}

export default Register;
