import React from 'react'
import './politica-privacidad.css'

function PoliticaPrivacidad() {
    return (
        <div className="container-privacidad">
          <div className="header-privacidad">
            <h1>Dream Fly - Política de privacidad</h1>
          </div>
          <p className="section-content-privacidad">Última actualización: 23/07/2024</p>
          <p className="section-content-privacidad">
            Esta Política de Privacidad describe cómo Dream Fly recopila, utiliza y protege la información personal que los usuarios proporcionan a través de nuestro sitio web.
          </p>
          <h2 className="section-title-privacidad">1. Información Recopilada</h2>
          <p className="section-content-privacidad">Recopilamos los siguientes datos personales:</p>
          <ul className="section-list-privacidad">
            <li className="section-content-privacidad">Nombre</li>
            <li className="section-content-privacidad">Correo electrónico</li>
            <li className="section-content-privacidad">Información de viajes (destinos, fechas, actividades)</li>
            <li className="section-content-privacidad">Preferencias (likes y dislikes)</li>
          </ul>
          <h2 className="section-title-privacidad">2. Uso de la Información</h2>
          <p className="section-content-privacidad">
            La información recopilada se utiliza para:
          </p>
          <ul className="section-list-privacidad">
            <li className="section-content-privacidad">Proporcionar y mejorar nuestros servicios</li>
            <li className="section-content-privacidad">Personalizar la experiencia del usuario</li>
            <li className="section-content-privacidad">Comunicarnos con los usuarios sobre actualizaciones y promociones</li>
          </ul>
          <h2 className="section-title-privacidad">3. Compartir Información</h2>
          <p className="section-content-privacidad">
            No compartimos la información personal con terceros, excepto cuando sea necesario para:
          </p>
          <ul className="section-list-privacidad">
            <li className="section-content-privacidad">Cumplir con la ley</li>
            <li className="section-content-privacidad">Proteger nuestros derechos</li>
            <li className="section-content-privacidad">Proporcionar servicios específicos a través de proveedores de confianza</li>
          </ul>
          <h2 className="section-title-privacidad">4. Seguridad</h2>
          <p className="section-content-privacidad">
            Implementamos medidas de seguridad adecuadas para proteger la información personal contra acceso no autorizado, alteración, divulgación o destrucción.
          </p>
          <h2 className="section-title-privacidad">5. Derechos de los Usuarios</h2>
          <p className="section-content-privacidad">
            Los usuarios tienen derecho a:
          </p>
          <ul className="section-list-privacidad">
            <li className="section-content-privacidad">Acceder a sus datos personales</li>
            <li className="section-content-privacidad">Rectificar información incorrecta</li>
            <li className="section-content-privacidad">Solicitar la eliminación de sus datos</li>
            <li className="section-content-privacidad">Restringir el procesamiento de sus datos</li>
          </ul>
          <h2 className="section-title-privacidad">6. Cambios a la Política de Privacidad</h2>
          <p className="section-content-privacidad">
            Nos reservamos el derecho de modificar esta política en cualquier momento. Notificaremos a los usuarios sobre cualquier cambio publicando la nueva política en nuestro sitio web y, si es necesario, a través de otros medios.
          </p>
          <h2 className="section-title-privacidad">7. Contacto</h2>
          <p className="section-content-privacidad">
            Para cualquier pregunta o inquietud sobre esta Política de Privacidad los usuarios pueden contactarse con Dream Fly a través de los canales de soporte indicados en el sitio web.
          </p>
        </div>
      );
}

export default PoliticaPrivacidad