import React from 'react';
import './about.css';

const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="section-title" aria-label="Acerca de Dream Fly">
          <h2>Acerca de Dream Fly</h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 container-imagen">
            <img
              src="img/about.png"
              className="img-responsive"
              alt="Logo de Dream Fly - Crea tu viaje personalizado"
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text" aria-label="Sobre nosotros">
              <p>{props.data ? props.data.paragraph : "Cargando..."}</p>
              <h3>¿Por qué elegir Dream Fly para tus viajes personalizados?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`} aria-labelledby={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`} aria-labelledby={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
