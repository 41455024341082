import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import './travelswipe.css';
import Trip from '../Trip/trip';
import LoadingAnimation from '../Loading/loadinganimation';
import SessionExpiredOverlay from '../Session-expired/session-expired-overlay';
import axios from 'axios';
import OverlayAlerta from '../OverlayAlerta/overlay-alerta';

const CACHE_LIKES = 'fav-trips-ids';
const CACHE_DISLIKES = 'dislike-trips-ids';
const TRAVEL_SWIPE = 'travel-swipe';
const CACHE_EXPIRATION_MS = 10 * 60 * 1000; // 10 minutos

const getFromCache = (CK) => {
    const cachedData = sessionStorage.getItem(CK);
    if (!cachedData) return null;

    const { timestamp, data } = JSON.parse(cachedData);
    if (Date.now() - timestamp > CACHE_EXPIRATION_MS) {
        sessionStorage.removeItem(CK);
        return null;
    }

    return data;
};

const saveToCache = (data, CK) => {
    const cacheData = {
        timestamp: Date.now(),
        data,
    };
    sessionStorage.setItem(CK, JSON.stringify(cacheData));
};

const TravelSwipe = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [swipeStyle, setSwipeStyle] = useState({});
    const [trips, setTrips] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sessionExpired, setSessionExpired] = useState(false);
    const [noMas, setNoMas] = useState(false);

    const styleSwipeLeft = { transform: 'translateX(-1000px) translateY(500px) rotate(-45deg)', transition: 'transform 0.5s ease' };
    const styleSwipeRight = { transform: 'translateX(1000px) translateY(500px) rotate(45deg)', transition: 'transform 0.5s ease' };

    useEffect(() => {
        if(!props.token) {
            setSessionExpired(true);
            return ;
        }
        handleTripsTravelSwipe();
    }, [props.token]);

    const handleTripsTravelSwipe = () => {
        let cachedData = getFromCache(TRAVEL_SWIPE);

        if (cachedData && cachedData.length > 0) {
            setTrips(cachedData);
            setLoading(false);
        } else {
            axios.post('https://dreamfly.es/api/travelSwipe', {
                limit: 10
            }, {
                headers: {
                    'x-access-token': props.token,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.trips.length === 0) {
                            setNoMas(true);
                            return;
                        } else {
                            handleTrips(response.data.trips);
                            saveToCache(response.data.trips, TRAVEL_SWIPE);
                            setLoading(false);
                        }
                    } else if (response.data.message === 'Token expired') {
                        setSessionExpired(true);
                    } else {
                        console.error("Algo ocurre (travel swipe else)");
                    }
                })
                .catch(err => {
                    console.error('Error:', err);
                });
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const deviceWidth = window.innerWidth;

            setSwipeStyle({ transform: 'translateX(0px)', transition: 'transform 1s ease' });
            setTimeout(() => {
                setSwipeStyle({ transform: `translateX(-${deviceWidth / 3}px) translateY(300px) rotate(-45deg)`, transition: 'transform 0.8s ease' });
                setTimeout(() => {
                    setSwipeStyle({ transform: 'translateX(0px)', transition: 'transform 0.8s ease' });
                    setTimeout(() => {
                        setSwipeStyle({ transform: `translateX(${deviceWidth / 3}px) translateY(300px) rotate(45deg)`, transition: 'transform 0.8s ease' });
                        setTimeout(() => {
                            setSwipeStyle({ transform: 'translateX(0px)', transition: 'transform 0.8s ease' });
                        }, 800);
                    }, 800);
                }, 800);
            }, 500);
        }
    }, [trips]);

    const likeTrip = (data) => {
        fetch('https://dreamfly.es/api/swipe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': props.token
            },
            body: JSON.stringify({ viaje_id: data.viaje_id, type: 'like' })
        })
            .then(response => response.json())
            .catch(err => {
                console.error(err);
            });
            handleLikedTripsCache(data);
    };

    const handleLikedTripsCache = (data) => {
        const cachedData = getFromCache(CACHE_LIKES);
        if (cachedData && cachedData.length > 0 && data.viaje_id) {
            const updatedViajes = [...cachedData, data.viaje_id];
            saveToCache(updatedViajes, CACHE_LIKES);
        }
    };
    
    const dislikeTrip = (data) => {
        fetch('https://dreamfly.es/api/swipe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': props.token
            },
            body: JSON.stringify({ viaje_id: data.viaje_id, type: 'dislike' })
        })
            .then(response => response.json())
            .catch(err => {
                console.error(err);
            });
            handleDislikedTripsCache(data);
    };

    const handleDislikedTripsCache = (data) => {
        const cachedData = getFromCache(CACHE_DISLIKES);
        if (cachedData && cachedData.length > 0 && data.viaje_id) {
            const updatedViajes = [...cachedData, data.viaje_id];
            saveToCache(updatedViajes, CACHE_DISLIKES);
        }
    };


    const handleTrips = (data) => {
        setTrips(data);
    };

    const handleLike = () => {
        setSwipeStyle(styleSwipeRight);
        likeTrip({ viaje_id: trips[currentIndex]._id });
        handleLikedTripsCache(trips[currentIndex]);
        setTimeout(() => {
            nextTravel();
        }, 500);
    };

    const handleDislike = () => {
        setSwipeStyle(styleSwipeLeft);
        dislikeTrip({ viaje_id: trips[currentIndex]._id });
        handleDislikedTripsCache(trips[currentIndex]);
        setTimeout(() => {
            nextTravel();
        }, 500);
    };

    const nextTravel = () => {
        setLoading(true);
        handleCurrentIndex()
            .then(() => {
                if (currentIndex < trips.length - 1) {
                    setTimeout(() => {
                        setSwipeStyle({ transform: 'translateX(0px) translateY(0px) rotate(0deg)', transition: 'none' });
                    }, 500);
                    setLoading(false);
                } else {
                    setCurrentIndex(0);
                    setTrips([]);
                    sessionStorage.removeItem(TRAVEL_SWIPE);
                    handleTripsTravelSwipe(); // Llamada después de eliminar el ítem
                }
            });
    };


    const handlers = useSwipeable({
        onSwiping: (eventData) => {
            const xOffset = eventData.deltaX;
            const yOffset = Math.abs(eventData.deltaX) / 2;
            const rotate = xOffset / 10;
            setSwipeStyle({ transform: `translateX(${xOffset}px) translateY(${yOffset}px) rotate(${rotate}deg)`, transition: 'none' });
        },
        onSwipedLeft: handleDislike,
        onSwipedRight: handleLike,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const handleCurrentIndex = () => {
        return new Promise((resolve) => {
            setCurrentIndex(prevIndex => prevIndex + 1);
            resolve();
        });
    };

    return (
        <>
            {sessionExpired && <SessionExpiredOverlay logout={props.logout} />}
            {noMas && <OverlayAlerta titulo="No quedan más viajes por mostrar" subtitulo="No quedan más viajes en la base de datos por mostrar. Espere a que más gente cree viajes para poder seguir explorando. Muchas gracias" redirect="https://dreamfly.es/mis-favoritos" btnMensaje="Explorar mis favoritos" />}
            {loading ? (<LoadingAnimation />) : (
                <div className="explore-destinations-container">
                    <div className="explore-encabezado" aria-label='Titulo travel swipe'>
                        <h1>Travel Swipe</h1>
                        <h2>Desliza hacia la derecha para dar like a un viaje. Desliza hacia la izquierda para dar dislike</h2>
                    </div>
                    <div className="travel-swipe">
                        {Array.isArray(trips) && trips.length > 0 ? (
                            <div className="travel-card" {...handlers} style={swipeStyle} aria-label='Carta travel swipe'>
                                <Trip trip={trips[currentIndex]} swipeMode={true} handleLike={handleLike} handleDislike={handleDislike} />
                            </div>
                        ) : (
                            <p>No quedan más viajes por mostrar :(</p>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default TravelSwipe;
