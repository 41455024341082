import React from 'react'
import './verified_approved.css';
import Logo from './Titulo.png';
import TickVerde from './tick_verde.png';

function VerifiedApproved() {

    const handleIniciarSesion = () => {
        window.location.href = '/login';
    }

    const handleRedirectHome = () => {
        window.location.href = '/';
    }

    return (
        <div className='verified-approved-container'>
            <div className='logo-verified-approved'>
                <img src={Logo} alt='Logo Dream Fly' onClick={handleRedirectHome}/>
            </div>
            <div className='verified-approved-body'>
                <div className="verified-approved-header">
                    <img src={TickVerde} alt='Tick verde'></img>
                    <h1>¡Cuenta verificada con éxito!</h1>
                    <p>¡Felicidades! Tu cuenta ha sido verificada con éxito. Ahora estás a solo un paso de comenzar tu próxima aventura.</p>
                    <p>Inicia sesión y comienza a planificar esos viajes soñados que siempre has querido realizar.</p>
                    <a
                  href="/login"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  INICIAR SESIÓN
                </a>
                </div>
            </div>
        </div>
    )
}

export default VerifiedApproved