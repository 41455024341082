import React from 'react';
import './terminos-condiciones.css';

function TerminosCondiciones() {
  return (
    <div className="container-terminos-condiciones">
      <div className="header-terminos-condiciones">
        <h1>Dream Fly - Términos y condiciones</h1>
      </div>
      <h2 className="section-title">1. Descripción del Servicio</h2>
      <p className="section-content">
        Dream Fly ofrece servicios de planificación de viajes, incluyendo la creación de itinerarios personalizados, sugerencias de actividades, alojamientos y opciones de comida basadas en las preferencias y presupuesto del usuario. El servicio también incluye funciones interactivas como Travel Swipe, una manera divertida de dar likes a diferentes viajes.
      </p>
      <h2 className="section-title">2. Registro y Acceso</h2>
      <p className="section-content">
        Para utilizar nuestros servicios, los usuarios deben registrarse en la plataforma, lo que incluye la opción de registrarse y loguearse con Google, así como la funcionalidad de "He olvidado mi contraseña". Los usuarios deben proporcionar información precisa y mantener la confidencialidad de su cuenta.
      </p>
      <h2 className="section-title">3. Creación y Gestión de Viajes</h2>
      <p className="section-content">
        Los usuarios pueden crear, guardar y gestionar sus viajes a través de las opciones "Crear viaje", "Mis viajes" y "Mis viajes favoritos". Estas herramientas permiten personalizar y almacenar sus planes de viaje para futuros accesos y modificaciones.
      </p>
      <h2 className="section-title">4. Funcionalidad de Travel Swipe</h2>
      <p className="section-content">
        Travel Swipe es una característica interactiva que permite a los usuarios explorar y dar like a diferentes opciones de viajes de una manera divertida y dinámica, similar a aplicaciones de citas.
      </p>
      <h2 className="section-title">5. Limitación de Responsabilidad</h2>
      <p className="section-content">
        Dream Fly no se responsabiliza por cualquier error en la planificación de viajes o por las decisiones tomadas basadas en las sugerencias proporcionadas. Toda la información y recomendaciones deben considerarse como meras sugerencias y pueden contener errores.
      </p>
      <h2 className="section-title">6. Reservas</h2>
      <p className="section-content">
        Las reservas de actividades, alojamiento y comidas deben realizarse a través de sitios de terceros. Dream Fly no asume responsabilidad alguna por las reservas realizadas a través de estos sitios externos.
      </p>
      <h2 className="section-title">7. Modificaciones y Actualizaciones</h2>
      <p className="section-content">
        Dream Fly se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Las actualizaciones se publicarán en el sitio web y será responsabilidad del usuario revisar periódicamente estos términos.
      </p>
      <h2 className="section-title">8. Uso del Servicio</h2>
      <p className="section-content">
        El uso de los servicios proporcionados por Dream Fly está sujeto a estos términos y condiciones. Al utilizar nuestros servicios, los usuarios aceptan cumplir con todas las políticas y regulaciones establecidas por Dream Fly.
      </p>
      <h2 className="section-title">9. Contacto</h2>
      <p className="section-content">
        Para cualquier duda o consulta sobre estos términos y condiciones, los usuarios pueden contactarse con Dream Fly a través de los canales de soporte indicados en el sitio web.
      </p>
    </div>
  );
}

export default TerminosCondiciones;
