import React, { useState } from 'react'
import './verified_denied.css';
import Logo from './Titulo.png';
import CruzRoja from './cruz_roja.png';

function VerifiedDenied() {

    const [email, setEmail] = useState('');
    const [inputError, setInputError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [enviadoCorrectamente, setEnviadoCorrectamente] = useState(false);


    const handleEnviarVerificacion = async () => {
        if (email === '') {
            setInputError(true);
            setTimeout(() => {
                setInputError(false);
            }, 4000);
        } else if (!isValidEmail(email)) {
            setEmailError(true);
            setTimeout(() => {
                setEmailError(false);
            }, 4000);
        } else {
            try {
                const response = await fetch('https://dreamfly.es/api/send-verification-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email })
                });
                if (response.ok) {
                    window.location.href = '/email-sent';
                } else {
                    window.location.href = '/email-not-sent'
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    // Función para validar el email
    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };


    const handleIniciarSesion = () => {
        window.location.href = '/login';
    }

    const handleRedirectHome = () => {
        window.location.href = '/';
    }
    
    return (
        <div className='verified-approved-container'>
            <div className='logo-verified-approved'>
                <img src={Logo} alt='Logo Dream Fly' onClick={handleRedirectHome}/>
            </div>
            <div className='verified-approved-body'>
                <div className="verified-approved-header">
                    <img src={CruzRoja} alt='Cruz Roja'></img>
                    <h1>Error verificando la cuenta</h1>
                    <p>Para reenviar el mensaje de verificación introduzca el correo electrónico en el campo de abajo y pulse el botón de Enviar Verificación.</p>
                    <div className="form-group verified-denied-input">
                        <input type="email" id="email" value={email} placeholder=" " autoComplete='username' onChange={(e) => setEmail(e.target.value)} />
                        <label htmlFor="email" className="floating-label">Email</label>
                    </div>
                    {inputError && <div className='alert alert-danger alert-verified-denied'>Por favor, rellene todos los campos.</div>}
                    {emailError && <div className='alert alert-danger alert-verified-denied'>Por favor, introduzca un email válido.</div>}
                    <a
                        onClick={handleEnviarVerificacion}
                        className="btn btn-custom btn-lg page-scroll"
                    >
                        ENVIAR VERIFICACIÓN
                    </a>
                </div>
            </div>
        </div>
    )
}

export default VerifiedDenied